import styled from 'styled-components';
import { cssMobileFirstBreakpoints } from '../../constants/mediaBreakpoints';

const StyledQuote = styled.div`
  && {
    box-shadow: 0px 2.5641px 15.3846px rgba(0, 0, 0, 0.15);
    border-radius: 10.2564px;
    padding: 16px;
  }

  .section {
    &__text {
      color: var(--theming-footer-gray);
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 8px;
    }

    &__author {
      font-weight: 400;
      font-size: 14px;
      color: var(--theming-quote-author-gray);
    }

    &__quote {
      margin-top: 11px;
    }
  }

  @media only screen and (${cssMobileFirstBreakpoints.desktopAndMore}) {
    && {
      padding: 24px 34px;
    }

    .section {
      &__quote {
        margin-bottom: 17px;
      }
    }
  }
`;

export default StyledQuote;
