import styled from 'styled-components';
import { cssMobileFirstBreakpoints } from '../../constants/mediaBreakpoints';

const StyledTransparentAvatarLeftSideContent = styled.div`
  && {
    background: var(--theming-light-blue);
    height: 410px;
    position: relative;
    overflow: hidden;

    .section {
      height: inherit;

      &__content {
        position: relative;
        z-index: 2;
        padding: 18px 24px;
        width: 333px;
      }

      &__title {
        font-weight: 600;
        font-size: 20px;
        margin-bottom: 16px;
      }

      &__color-layer {
        background: #edf9fec7;
        height: inherit;
        width: auto;
        position: relative;
        z-index: 2;
      }

      &__description {
        font-weight: 400;
        font-size: 14px;
      }

      &__button {
        margin-top: 16px;
      }

      &__avatar {
        height: inherit;
        max-height: 380px;
        z-index: 1;
        position: absolute;
        bottom: 0;
        right: -40%;
      }
    }
  }

  && {
    @media only screen and (${cssMobileFirstBreakpoints.mobileAndMore}) {
      height: 444px;

      .section {
        &__content {
          padding-left: 103px;
          width: 520px;
        }

        &__title {
          margin-top: 64px;
        }

        &__description {
          font-size: 16px;
        }

        &__avatar {
          max-height: initial;
        }
      }
    }

    && {
      @media only screen and (min-width: 936px) {
        .section {
          &__color-layer {
            background: transparent;
          }
        }
      }
    }

    && {
      @media only screen and (${cssMobileFirstBreakpoints.tabletAndMore}) {
        .section {
          &__avatar {
            right: 0;
          }
        }
      }
    }
  }
`;

export default StyledTransparentAvatarLeftSideContent;
