import styled from 'styled-components';

const StyledButton = styled.button<{ marginLeft?: string; padding?: string; width: string; height: string; borderColor?: string; contentColor?: string; fontSize?: string; background?: string }>`
  && {
    border-radius: 16px;
    font-family: 'Poppins';
    font-weight: 600;
    cursor: pointer;
    max-width: 100%;
    width: ${({ width }) => `${width}`};
    height: ${({ height }) => `${height}`};
    background: ${({ background }) => `${background}`};
    border: 1px solid ${({ borderColor }) => `${borderColor}`};
    color: ${({ contentColor }) => `${contentColor}`};
    font-size: ${({ fontSize }) => `${fontSize}`};
    padding: ${({ padding }) => `${padding}`};
    margin-left: ${({ marginLeft }) => `${marginLeft}`};
  }
`;

export default StyledButton;
