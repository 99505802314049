import React from 'react';
import StyledSimpleCheckbox from './StyledSimpleCheckbox';

interface SimpleCheckboxProps {
  checked: boolean;
  onChange: (newValue: string) => void;
}

const SimpleCheckbox = ({ checked, onChange }: SimpleCheckboxProps) => (
  <StyledSimpleCheckbox checked={checked} onChange={e => onChange((e.target as any).value)} />
);

export default SimpleCheckbox;
