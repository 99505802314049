import { graphql } from 'gatsby';
import React from 'react';
import Img from 'gatsby-image';
import WithCenteredContent from '../../HOCs/WithCenteredContent/WithCenteredContent';

const SingleImageParagraph = ({ node }: any) => {
  const { fieldImage, singleImageGatsbyFile } = node;

  return (
    <WithCenteredContent hasSmallCenteredContent>
      <Img fluid={singleImageGatsbyFile?.childImageSharp?.fluid} alt={fieldImage?.alt} />
    </WithCenteredContent >
  );
};

export default SingleImageParagraph;

export const fragment = graphql`
  fragment ParagraphSingleImage on DRUPAL_ParagraphSingleImageParagraph {
    id
    type: entityBundle
    fieldSpacingAfter
    fieldImage {
      alt
      entity {
        uri {
          url
        }
      }
    }
    singleImageGatsbyFile {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
  }
`;
