import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import CustomButton from '../../components/button';
import { ButtonBackgroundTypes } from '../../components/button/button';
import axios from 'axios';
import {StringParam, useQueryParam} from "use-query-params";
import { useLocation } from '@reach/router';
// @ts-ignore
import enTranslations from '../../intl/en.json';
// @ts-ignore
import roTranslations from '../../intl/ro.json';
import {Link} from "gatsby";

const getTranslation = (language: any, key: any) => {
    const translations = language === 'en' ? enTranslations : roTranslations;
    return translations[key] || key; // Return the translation or the key itself if not found
};

const ContactForm = ({ fields }: any) => {
    const location = useLocation();
    const { pathname } = location;
    const [,language] = pathname.split('/'); // Assuming language code is the first segment of the pathname
    const translatedAgree = getTranslation(language, 'agree');
    const translatedPolicy = getTranslation(language, 'urls.privacy');

    const isMessageSentSuccess = useQueryParam('success', StringParam);
    const isMessageSentWithError = useQueryParam('error', StringParam);
    const {
        register,
        watch,
        getValues,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            name: '',
            phone: '',
            email: '',
            message: '',
            privacy_policy: false,
        },
    });

    const submitForm = async (data: any) => {
        try {
            const {
                data: {}
            } = await axios.post(process.env.DRUPAL_URL + '/api/webform_submission/contact', {
                name: data.name,
                phone: data.phone,
                email: data.email,
                message: data.message,
                privacy_policy: data.privacy_policy
            }).then( (result) => {
                if (result.data === 'Success') {
                    window.location.href = window.location.href + "?success=true";
                } else {
                    window.location.href = window.location.href + "?error=true";
                }
                return result;
            });
        } catch (error) {}
    };

    const onSubmit = (data: any) => {
        submitForm(data);
    };

    const nonEmpty = (value: any) => {
        return value.trim() !== '';
    };

    const validEmail = (value: any) => {
        const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

        return (value.trim().match(validRegex) !== null);
    };

    return (
        <div className='section__form'>
            <form onSubmit={handleSubmit(onSubmit)}>
                {isMessageSentSuccess[0] === 'true' && <p className='alert alert-success'>Thank you for your message!</p>}
                {isMessageSentWithError[0] === 'true' && <p className='alert alert-danger'>Something went wrong, try again!</p>}
                <div className='section__form-row'>
                    <label className='section__form-label'>{fields.fieldLabels[0]}<sup>*</sup></label>
                    <div className='section__form-input'>
                        <input type={fields.fieldTypes[0]} {...register('name', {validate: {
                                nonEmpty: nonEmpty,
                            },})} />
                    </div>
                    {errors.name?.type === 'nonEmpty' && (
                        <p className='section__form-input-error'>Please fill in your {fields.fieldLabels[0]}</p>
                    )}
                </div>

                <div className='section__form-row'>
                    <label className='section__form-label'>{fields.fieldLabels[1]}<sup>*</sup></label>
                    <div className='section__form-input'>
                        <input type={fields.fieldTypes[1]} {...register('phone', {validate: {
                                nonEmpty: nonEmpty,
                            },})} />
                    </div>
                    {errors.phone?.type === 'nonEmpty' && (
                        <p className='section__form-input-error'>Please fill in your {fields.fieldLabels[1]}</p>
                    )}
                </div>

                <div className='section__form-row'>
                    <label className='section__form-label'>{fields.fieldLabels[2]}<sup>*</sup></label>
                    <div className='section__form-input'>
                        <input type={fields.fieldTypes[2]} {...register('email', {validate: {
                                validEmail: validEmail
                            },})}/>
                    </div>
                    {errors.email?.type === 'validEmail' && (
                        <p className='section__form-input-error'>Please fill in a valid {fields.fieldLabels[2]}</p>
                    )}
                </div>

                <div className='section__form-row'>
                    <label className='section__form-label'>{fields.fieldLabels[3]}<sup>*</sup></label>
                    <div className='section__form-textarea'>
                        <textarea {...register('message', {validate: {
                                nonEmpty: nonEmpty,
                            },})}></textarea>
                    </div>
                    {errors.message?.type === 'nonEmpty' && (
                        <p className='section__form-input-error'>Please fill in {fields.fieldLabels[3]}</p>
                    )}
                </div>

                <div className='section__form-row'>
                    <div className='section__form-radio-input flex'>
                        <div>
                            <input type='checkbox' id='privacy_policy' {...register('privacy_policy', { required: true })} />&nbsp;
                        </div>
                        <div>
                            <label htmlFor='privacy_policy' className='section__form-label'>{translatedAgree} <Link target="_blank" to={translatedPolicy}>{fields.fieldLabels[4]}</Link><sup>*</sup></label>
                        </div>
                    </div>
                    {errors.privacy_policy && (
                        <p className='section__form-input-error'>{fields.fieldLabels[4]} is required</p>
                    )}
                </div>

                <div className='section__form-button'>
                    <CustomButton
                        color='var(--theming-white)'
                        backgroundColor={errors.name || errors.phone || errors.email || errors.message || errors.privacy_policy ? ButtonBackgroundTypes.PRIMARYDISABLED : ButtonBackgroundTypes.PRIMARY}
                        border={errors.name || errors.phone || errors.email || errors.message|| errors.privacy_policy ? 'var(--theming-text-gray)' : 'var(--theming-main-blue)'}
                        width='280px'
                    >
                        {fields.fieldSubmit}
                    </CustomButton>
                </div>
            </form>
        </div>
    );
};

export default ContactForm;
