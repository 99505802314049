import { Navbar } from 'react-bootstrap';
import styled from 'styled-components';
import { cssBreakpoints } from '../../../constants/mediaBreakpoints';

const StyledHeader = styled.div`
  .header-content {
    &__donate-banner {
      display: none;
    }

    &__options {
      display: flex;
      margin-left: 16px;
    }

    &__option {
      list-style: none;
      margin-right: 23px;
    }

    &__link {
      color: black;
      font-weight: 400 !important;
      text-decoration: none;
      font-size: 15px;
      font-weight: bold;
      width: max-content;
      white-space: nowrap;

      &--active {
        font-weight: 700 !important;

        &::after {
          content: '';
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: #0356e8;
          display: block;
          margin: 0px auto;
        }
      }
    }

    &__right-panel {
      display: flex;
      align-items: center;
    }

    &__left-panel {
      display: flex;
      align-items: baseline;
      margin-top: 7px;
    }

    &__flag {
      margin-right: 20px;
      margin-top: 7px;

      &--mobile {
        display: none;
      }
    }

    &__hamburger {
      width: 24px;
      height: 24px;
    }

    @media only screen and (${cssBreakpoints.mediumAndLess}) {
      &__donate-banner {
        display: initial;
        background: var(--theming-main-blue);
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 40px;
        font-weight: 600;
        color: white;
      }

      &__logo {
        width: 119px;
        height: 40px;
        margin-left: 24px;
      }

      &__flag {
        &--mobile {
          display: initial;
          margin-right: 23px;
        }

        &--desktop {
          display: none;
        }
      }

      &__donate {
        display: none;
      }

      &__left-panel {
        display: initial;
        margin-left: 24px;
      }

      &__link {
        &--active {
          &::after {
            display: inline-block;
            margin-left: 8px;
          }
        }
      }
    }
  }
  @media only screen and (${cssBreakpoints.extraSmall}) {
    && {
      margin-bottom: 110px;
    }
  }
`;

const StyledNavbar = styled(Navbar)`
  && {
    margin: 10px auto;
    max-width: var(--size-desktop-width);
  }

  @media only screen and (${cssBreakpoints.mediumAndLess}) {
    && {
      margin-top: 0px;
      padding-top: 0px;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 9;
    }
  }
`;

const CustomNavbarToggle = styled(Navbar.Toggle)`
  && {
    border: none;
  }
`;

export { StyledHeader, StyledNavbar, CustomNavbarToggle };
