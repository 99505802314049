import styled from 'styled-components';
import { breakpoints, cssBreakpoints } from '../../constants/mediaBreakpoints';

const StyledWithCenteredContent = styled.div<{ fullScreenOnMobile?: boolean; hasSmallCenteredContent?: boolean }>`
  && {
    @media only screen and (max-width: calc(${breakpoints.cssCenteredContentMaxWidth} - 1px)) {
      padding: 0px 25px;
    }

    @media only screen and (min-width: ${breakpoints.cssCenteredContentMaxWidth}) {
      margin: 0px auto;
      max-width: ${({ hasSmallCenteredContent }) => (hasSmallCenteredContent ? '795px' : 'var(--size-desktop-width)')};
    }

    .white-background {
      box-shadow: 0px 2.5641px 15.3846px rgba(0, 0, 0, 0.15);
      border-radius: 10.2564px;
      padding: 36px;
      background-color: #ffffff;
    }
  }

  ${({ fullScreenOnMobile }) => {
    if (fullScreenOnMobile) {
      return `
        @media only screen and (${cssBreakpoints.mediumAndLess}) {
          padding: initial;
        }
        `;
    }
  }};
`;
export default StyledWithCenteredContent;
