import { graphql } from 'gatsby';
import React from 'react';
import WithCenteredContent from '../../HOCs/WithCenteredContent/WithCenteredContent';
import StyledAvatarGroup from './styledAvatarsGroup';
import Img from 'gatsby-image';

const AvatarsGroup = ({ sectionTitle, cardData }: any) => {
  const { results, avatarImageGatsbyFile } = cardData || {};
  return (
    <StyledAvatarGroup>
      {/* <div > */}
      <WithCenteredContent>
        <div className='section'>
          {sectionTitle && <div className='section__title' dangerouslySetInnerHTML={{ __html: sectionTitle }} />}
          <div className='section__avatars'>
            {(cardData?.results || []).map((profile: any, index: number) => (
              <div className='avatar' key={`${index}avatars`}>
                <Img fixed={avatarImageGatsbyFile[index]?.childImageSharp?.fixed} alt={profile?.title || ''} />
                <div className='avatar__title' dangerouslySetInnerHTML={{ __html: profile.title }} />
                <div
                  //className={profile.field_is_founder === '1' ? 'avatar__founding_member' : 'avatar__description'}
                  className={'avatar__description'}
                  dangerouslySetInnerHTML={{ __html: profile.field_member_function }}
                />
              </div>
            ))}
          </div>
        </div>
        {/* </div> */}
      </WithCenteredContent>
    </StyledAvatarGroup>
  );
};

export default AvatarsGroup;

export const fragment = graphql`
  fragment ParagraphMembersGraphql1ViewResult on DRUPAL_MembersAssistGraphql1ViewResult {
    type: __typename
    results {
      title
      field_member_function
      field_is_founder
      field_order
      field_image
    }
    avatarImageGatsbyFile {
      childImageSharp {
        fixed(width: 176, height: 176, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
  }
  fragment ParagraphVolunteersGraphql1ViewResult on DRUPAL_VolunteersAssistGraphql1ViewResult {
    type: __typename
    results {
      title
      field_volunteer_function
      field_order
      field_image
    }
    avatarImageGatsbyFile {
      childImageSharp {
        fixed(width: 176, height: 176, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
  }
`;
