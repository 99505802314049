import { graphql } from 'gatsby';
import React from 'react';
import Quote from '../../drupalSections/Quote/quote';

const TestimonialParagraph = ({ node }: any) => {
  const { fieldTestimonial } = node;
  return <Quote content={fieldTestimonial.entity} />;
};

export default TestimonialParagraph;

export const fragment = graphql`
  fragment ParagraphTestimonial on DRUPAL_ParagraphTestimonialParagraph {
    id
    type: entityBundle
    fieldSpacingAfter
    fieldTestimonial {
      entity {
        ... on DRUPAL_NodeTestimonial {
          author: fieldTestimonialAuthor
          authorFunction: fieldTestimonialAuthorFunctio
          testimonial: body {
            processed
          }
        }
      }
    }
  }
`;
