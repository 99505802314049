import { graphql } from 'gatsby';
import React from 'react';
import { getSection } from '../pageTypeToSection';

const RightContainerParagraph = ({ node }: any) => {
    const paragraphs = node.paragraphs.map(getSection);
    return <div className='section section-right'>{paragraphs}</div>
}

export default RightContainerParagraph;

export const fragment = graphql`
  fragment ParagraphRightContainer on DRUPAL_ParagraphRightContainerParagraph {
    id
    type: entityBundle
    paragraphs: fieldRightParagraphs {
        entity {
            ...ParagraphView
            ...ParagraphLongText
            ...ParagraphTestimonial
            ...ParagraphSingleImage
            ...ParagraphMultipleImages
            ...ParagraphForm
        }
    }
  }
`;

