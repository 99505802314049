import { graphql } from 'gatsby';
import React from 'react';
import Img from 'gatsby-image/withIEPolyfill';

import WithCenteredContent from '../../HOCs/WithCenteredContent/WithCenteredContent';
import { StyledSponsorsAndPartners } from './styledSponsorsAndPartners';

const ITEMS_IN_ROW = 4;

const SponsorsAndPartners = ({ sectionTitle, cardData }: any) => {
  const { results, sponsorPartnerImageGatsbyFile } = cardData || {};

  // This is to make sure that the last row of sponsors/partners is always 4 items
  const emptySpaces = [...Array(ITEMS_IN_ROW - (results.length % ITEMS_IN_ROW)).keys()];

  return (
    <WithCenteredContent hasSmallCenteredContent={false}>
      <StyledSponsorsAndPartners>
        {sectionTitle && <h3 className='text-primary section-title' dangerouslySetInnerHTML={{ __html: sectionTitle }} />}

        <div className='sponsors'>
          {(results || []).map((sponsorPartner: any, index: number) => (
            <div className='sponsor' key={`${index}sponsors`}>
              <a rel='noreferrer' className={sponsorPartner?.field_link ? "active" : "disabled"} href={sponsorPartner?.field_link} target='_blank' key={`${index}sponsors2`}>
                <Img
                  className='sponsor-partner-image'
                  fluid={sponsorPartnerImageGatsbyFile[index]?.childImageSharp?.fluid}
                  alt={sponsorPartner?.title || ''}
                  objectFit='contain'
                  style={{
                    maxWidth: '183px',
                    maxHeight: '183px',
                    width: '100%',
                  }}
                />
              </a>
            </div>
          ))}

          {emptySpaces.map((emptySpace: number, index: number) => (
            <div key={index} className='sponsor-empty'></div>
          ))}
        </div>
      </StyledSponsorsAndPartners>
    </WithCenteredContent>
  );
};

export default SponsorsAndPartners;
export const fragment = graphql`
  fragment ParagraphSponsorsGraphql1ViewResult on DRUPAL_SponsorsAssistGraphql1ViewResult {
    type: __typename
    results {
      title
      field_link
      field_image
    }
    sponsorPartnerImageGatsbyFile {
      childImageSharp {
        fluid(maxWidth: 183, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
          presentationWidth
        }
      }
    }
  }
  fragment ParagraphPartnersGraphql1ViewResult on DRUPAL_PartnersAssistGraphql1ViewResult {
    type: __typename
    results {
      title
      field_link
      field_image
    }
    sponsorPartnerImageGatsbyFile {
      childImageSharp {
        fluid(maxWidth: 183, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
          presentationWidth
        }
      }
    }
  }
  fragment ParagraphPartnersGraphql2ViewResult on DRUPAL_PartnersAssistGraphql2ViewResult {
    type: __typename
    results {
      title
      field_link
      field_image
    }
    sponsorPartnerImageGatsbyFile {
      childImageSharp {
        fluid(maxWidth: 183, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
          presentationWidth
        }
      }
    }
  }
`;
