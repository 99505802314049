import styled from 'styled-components';
import { cssMobileFirstBreakpoints } from '../../constants/mediaBreakpoints';

const StyledFourCardsGroup = styled.div`
  .section {
    &__title {
      text-align: center;
      font-weight: 600;
      font-size: 24px;
    }

    &__cards {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  .card {
    display: flex;
    flex-direction: row;
    margin: 10px;
    border-radius: 16px;
    padding: 16px 20px 23px 10px;
    border: none;

    &__left-side {
      width: fit-content;
    }

    &__right-side {
      width: fit-content;
      margin-left: 15px;
    }

    &__title {
      font-size: 19px;
      font-weight: 600;
    }

    &__description {
      font-size: 14px;
    }

    &__image {
      width: 39px;
      height: 42px;
    }
  }

  @media only screen and (${cssMobileFirstBreakpoints.mobileAndMore}) {
    .section {
      &__title {
        font-size: 32px;
        display: block;
        max-width: 705px;
        margin: 0px auto;
        margin-bottom: 50px;
      }
    }

    .card {
      width: 80%;
      display: initial;
      padding-left: 16px;
      padding-right: 16px;

      &__title {
        margin-bottom: 11px;
      }

      &__right-side {
        margin-left: initial;
      }

      &__icon {
        margin-bottom: 11px;
      }
    }
  }

  @media only screen and (${cssMobileFirstBreakpoints.tabletAndMore}) {
    .card {
      width: 40%;
    }
  }

  @media only screen and (${cssMobileFirstBreakpoints.largest}) {
    .card {
      width: 258px;
    }
  }
`;

export default StyledFourCardsGroup;
