import React, { useState } from 'react';
import StyledImageCarousel from './styledImageCarousel';
import Img from 'gatsby-image';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { breakpoints } from '../../constants/mediaBreakpoints';
import WithCenteredContent from '../../HOCs/WithCenteredContent/WithCenteredContent';
import 'swiper/css';
import 'swiper/css/navigation';

const ImageCarousel = (images: any) => {
  const fieldImages = images.fieldImages;
  const multipleImagesGatsbyFile = images.multipleImages;
  const spacingAfter = images.spacingAfter;

  const [prevEl, setPrevEl] = useState<HTMLElement | null>(null);
  const [nextEl, setNextEl] = useState<HTMLElement | null>(null);

  const [imageToShow, setImageToShow] = useState(undefined);
  const [altToShow, setAltToShow] = useState('');
  const [lightboxDisplay, setLightBoxDisplay] = useState(false);

  const breakpointMedium = breakpoints.small.replace('px', '');

  const showImage = (image: any, alt: any) => {
    setImageToShow(image);
    setAltToShow(alt);
    setLightBoxDisplay(true);
  };

  const hideLightBox = () => {
    setLightBoxDisplay(false);
  };

  const showNext = (e: any) => {
    e.stopPropagation();
    let currentIndex = multipleImagesGatsbyFile.findIndex((image: any) => image.childImageSharp?.fluid === imageToShow);
    if (currentIndex >= multipleImagesGatsbyFile.length - 1) {
      setLightBoxDisplay(false);
    } else {
      let nextImage = multipleImagesGatsbyFile[currentIndex + 1];
      setImageToShow(nextImage.childImageSharp?.fluid);
    }
  };

  const showPrev = (e: any) => {
    e.stopPropagation();
    let currentIndex = multipleImagesGatsbyFile.findIndex((image: any) => image.childImageSharp?.fluid === imageToShow);
    if (currentIndex <= 0) {
      setLightBoxDisplay(false);
    } else {
      let nextImage = multipleImagesGatsbyFile[currentIndex - 1];
      setImageToShow(nextImage.childImageSharp?.fluid);
    }
  };

  return (
    <WithCenteredContent>
      <StyledImageCarousel>
        <div className='carousel'>
          <div className='carousel__button--prev' ref={node => setPrevEl(node)}>
            {'<'}
          </div>
          <Swiper
            navigation={{ prevEl, nextEl }}
            modules={[Navigation]}
            spaceBetween={30}
            breakpoints={{
              [breakpointMedium]: {
                slidesPerView: fieldImages.length < 3 ? fieldImages.length : 3,
              },
            }}
          >
            {multipleImagesGatsbyFile.map((gatsbyFile: any, i: any) =>
              fieldImages.map(
                (fieldImage: any, j: any) =>
                  i === j && (
                    <SwiperSlide key={`${i}swiperSlide`}>
                      <div onClick={() => showImage(gatsbyFile?.childImageSharp?.fluid, fieldImage?.alt)}>
                        <Img className='carousel__img' fluid={gatsbyFile?.childImageSharp?.fluid} alt={fieldImage?.alt} />
                      </div>
                    </SwiperSlide>
                  ),
              ),
            )}
          </Swiper>
          <div className='carousel__button--next' ref={node => setNextEl(node)}>
            {'>'}
          </div>
        </div>

        {lightboxDisplay && (
          <div className='lightbox' onClick={hideLightBox}>
            <div className='lightbox__container'>
              <button className='carousel__button--prev' onClick={showPrev}>
                {'<'}
              </button>
              <Img className='lightbox__img' fluid={imageToShow} alt={altToShow} onClick={(e: any) => e.stopPropagation()} />
              <button className='carousel__button--next' onClick={showNext}>
                {'>'}
              </button>
            </div>
          </div>
        )}
      </StyledImageCarousel>
    </WithCenteredContent>
  );
};

export default ImageCarousel;
