import React, { ReactChild } from 'react';
import StyledWithCenteredContent from './StyledWithCenteredContent';

const WithCenteredContent = ({
  children,
  fullScreenOnMobile,
  hasSmallCenteredContent,
}: {
  children: ReactChild;
  fullScreenOnMobile?: boolean;
  hasSmallCenteredContent?: boolean;
}) => {
  return (
    <StyledWithCenteredContent fullScreenOnMobile={fullScreenOnMobile} hasSmallCenteredContent={hasSmallCenteredContent}>
      {children}
    </StyledWithCenteredContent>
  );
};

export default WithCenteredContent;
