import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import WithCenteredContent from '../../HOCs/WithCenteredContent/WithCenteredContent';
import StyledQuote from './styledQuote';

const Quote = ({ content }: any) => {
  const { testimonial, author = '', authorFunction = '' } = content || {};

  return (
    <WithCenteredContent hasSmallCenteredContent>
      <StyledQuote>
        <div className='section'>
          <StaticImage className='section__quote' src='../../images/svg/quote.svg' alt='Quote' />
          <div className='section__text' dangerouslySetInnerHTML={{ __html: testimonial?.processed || '' }} />
          <div
            className='section__author'
            dangerouslySetInnerHTML={{
              __html: `${author} - ${authorFunction}`,
            }}
          />
        </div>
      </StyledQuote>
    </WithCenteredContent>
  );
};

export default Quote;
