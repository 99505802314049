import styled from 'styled-components';
import { cssMobileFirstBreakpoints } from '../../constants/mediaBreakpoints';

const panelMargin = '16px';

const StyledHeaderImageCarousel = styled.div`
  && {
    position: relative;
    margin-bottom: 300px;
  }
  
  .carouselImage img{
    clip-path: polygon(0 0, 100% 0, 100% 86%, 89% 91%, 76% 96%, 64% 99%, 50% 100%, 36% 99%, 24% 96%, 11% 91%, 0 86%);
  }

  .panel {
    position: absolute;
    background: #fff;
    border-radius: 16px;
    left: 50%;
    transform: translate(-50%, 0);
    width: min(var(--size-desktop-width), calc(100% - ${panelMargin} * 2));
    padding: 30px;
    bottom: -280px;
    z-index: 2;
    text-align: center;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.05);

    &__title {
      color: var(--theming-navy-blue);
      font-weight: 800;
      font-size: 28px;
      margin-bottom: 16px;

      h1 {
        font-weight : 800;
      }
    }

    &__description {
      color: var(--theming-navy-blue-light);
      font-weight: 600;
      font-size: 18px;
      margin-bottom: 16px;
    }
  }

  @media only screen and (${cssMobileFirstBreakpoints.tabletAndMore}) {
    && {
      margin-bottom: 150px;
    }

    .panel {
      bottom: -150px; 
      padding: 48px;
      &__title {
        font-size: 40px;
      }    
    }
  }
`;

export default StyledHeaderImageCarousel;
