import React from 'react';
import StyledCenteredContentImage from './styledDonateCenteredContentImage';
import DonateButton from '../../components/DonateButton/DonateButton';
import { GenericParagraphProps } from '../../drupalPagesGenerator/GenericParagraphs/genericParagraph.componentProps';
import { navigate } from 'gatsby';
import Img from 'gatsby-image';

const DonateCenteredImage = ({ button, desktopImages, description, title }: GenericParagraphProps) => {
  return (
    <StyledCenteredContentImage>
      <div className='section'>
        {title && <div className='section__title' dangerouslySetInnerHTML={{ __html: title }}></div>}
        {description && <div className='section__description' dangerouslySetInnerHTML={{ __html: description }}></div>}
        {button?.buttonText && <DonateButton onClick={() => navigate(button.ref)}>{button.buttonText}</DonateButton>}
      </div>
      {desktopImages?.[0] && <Img fluid={desktopImages?.[0]?.image?.childImageSharp?.fluid} alt={desktopImages?.[0].metadata.alt} />}
    </StyledCenteredContentImage>
  );
};

export default DonateCenteredImage;
