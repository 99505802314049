import React from 'react';
import WithCenteredContent from '../../HOCs/WithCenteredContent/WithCenteredContent';
import StyledTransparentAvatarLeftSideContent from './styledTransparentAvatarLeftSideContent';
import Button from '../../components/button';
import DonateButton from '../../components/DonateButton/DonateButton';
import { ButtonBackgroundTypes } from '../../components/button/button';
import { GenericParagraphProps } from '../../drupalPagesGenerator/GenericParagraphs/genericParagraph.componentProps';
import { navigate } from 'gatsby';
import Img from 'gatsby-image';

const TransparentAvatarLeftSideContent = ({ button, desktopImages, description, title }: GenericParagraphProps) => {
  return (
    <WithCenteredContent fullScreenOnMobile>
      <StyledTransparentAvatarLeftSideContent>
        <div className='section'>
          <div className='section__color-layer'>
            <div className='section__content'>
              {title && <div className='section__title' dangerouslySetInnerHTML={{ __html: title }} />}
              {description && <div className='section__description' dangerouslySetInnerHTML={{ __html: description }} />}
              {button?.buttonText && (
                <div className='section__button'>
                  {button?.isDonate && <DonateButton onClick={() => navigate(button.ref)}>{button.buttonText}</DonateButton>}
                  {!button?.isDonate &&
                    <Button
                      onClick={() => navigate(button?.ref)}
                      color='var(--theming-main-blue)'
                      backgroundColor={ButtonBackgroundTypes.TRANSPARENT}
                      border='var(--theming-main-blue)'
                    >
                      {button?.buttonText}
                    </Button>
                  }
                </div>
              )}
            </div>
          </div>
        </div>
        {desktopImages?.[0] && (
          <Img
            fluid={desktopImages?.[0].image?.childImageSharp?.fluid}
            alt={desktopImages?.[0].metadata.alt}
            className='section__avatar'
            style={{
              position: 'absolute',
              maxWidth: '589px',
              width: '100%',
            }}
            imgStyle={{ objectFit: 'none' }}
          />
        )}
      </StyledTransparentAvatarLeftSideContent>
    </WithCenteredContent>
  );
};

export default TransparentAvatarLeftSideContent;
