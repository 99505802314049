import styled from 'styled-components';
import { cssMobileFirstBreakpoints } from '../../constants/mediaBreakpoints';

const StyledAvatarGroup = styled.div`
  .section {
    &__title {
      text-align: center;
      color: var(--theming-color-text-dark);
      font-size: 28px;
      font-weight: 600;
      margin-bottom: 16px;
    }

    &__avatars {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 50px;
    }
  }
  .avatar {
    &__title {
      text-align: center;
      margin-top: 16px;
      font-weight: 600;
      font-size: 14px;
      color: var(--theming-color-text-dark);
    }

    &__description {
      text-align: center;
      color: var(--theming-color-text-dark);
      font-size: 14px;
    }

    &__founding_member {
      text-align: center;
      color: var(--theming-color-title-pink);
      font-size: 14px;
    }
  }

  @media only screen and (${cssMobileFirstBreakpoints.mobileAndMore}) {
    .section {
      &__title {
        margin-bottom: 50px;
      }
    }
  }
`;

export default StyledAvatarGroup;
