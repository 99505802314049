import { graphql } from 'gatsby';
import React from 'react';
import { getSection } from '../pageTypeToSection';

const LeftContainerParagraph = ({ node }: any) => {
    const paragraphs = node.paragraphs.map(getSection);
    return <div className='section section-left'>{paragraphs}</div>
}

export default LeftContainerParagraph;

export const fragment = graphql`
  fragment ParagraphLeftContainer on DRUPAL_ParagraphLeftContainerParagraph {
    id
    type: entityBundle
    paragraphs: fieldLeftParagraphs {
        entity {
            ...ParagraphView
            ...ParagraphLongText
            ...ParagraphTestimonial
            ...ParagraphSingleImage
            ...ParagraphMultipleImages
            ...ParagraphForm
        }
    }
  }
`;
