import React from 'react';
import WithCenteredContent from '../../HOCs/WithCenteredContent/WithCenteredContent';

const TextContent = ({ content, hasWhiteBackground }: any) => (
    <WithCenteredContent hasSmallCenteredContent>
        <div dangerouslySetInnerHTML={{ __html: content }} className={hasWhiteBackground ? 'white-background' : ''} />
    </WithCenteredContent >
);

export default TextContent;
