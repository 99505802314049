import { graphql } from 'gatsby';
import React from 'react';
import LeftContainerParagraph from '../LeftContainerParagraph/LeftContainerParagraph';
import RightContainerParagraph from '../RightContainerParagraph/RightContainerParagraph';
import WithCenteredContent from '../../HOCs/WithCenteredContent/WithCenteredContent';
import Container from '../../drupalSections/Container/styledContainer';


const containerSectionTypes: any = {
    left_container_paragraph: LeftContainerParagraph,
    right_container_paragraph: RightContainerParagraph,
};

const ContainerParagraph = ({ node }: any) => {
    const { paragraphs } = node;
    let containsImage = false;
    const content = paragraphs.map((element) => {
        let { type, id, paragraphs } = element.entity || {};
        paragraphs.forEach(elem => {
            if (elem.entity.type === 'single_image_paragraph') {
                containsImage = true;
            }
        });

        if (containerSectionTypes?.[type]) {
            let Section = containerSectionTypes[type];
            return (
                <Section key={`${id}container`} node={element.entity} />
            );
        }
    });
    return (
        <WithCenteredContent hasSmallCenteredContent={containsImage}>
            <Container>
                {content}
            </Container>
        </WithCenteredContent>
    );
}

export default ContainerParagraph;

export const fragment = graphql`
  fragment ParagraphContainer on DRUPAL_ParagraphContainerParagraph {
    id
    type: entityBundle
    fieldSpacingAfter
    paragraphs: fieldParagraphs {
        entity {
            ...ParagraphLeftContainer
            ...ParagraphRightContainer
        }
    }
  }
`;