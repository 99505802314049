import React from 'react';
import WithCenteredContent from '../../HOCs/WithCenteredContent/WithCenteredContent';
import Button from '../../components/button';
import { ButtonBackgroundTypes } from '../../components/button/button';
import StyledActivitySummary from './styledActivitySummary';
import { graphql, Link, navigate } from 'gatsby';
import Img from 'gatsby-image';

const isBrowser = typeof window !== "undefined"
const ActivitySummary = ({ cardData, sectionTitle, buttonLinkText, buttonLinkUri }: any) => {
  const { results, imageGatsbyFile, imageGatsbyFile2, count } = cardData || {};
  const activityFeatureImage = imageGatsbyFile || imageGatsbyFile2;

  const headerRightSideBtnText = buttonLinkText || '';
  const headerRightSideBtnUri = buttonLinkUri || '';

  let origin = '', pathName = '';
  let isFirst = false, isLast = false;
  let numPages = 0, currentPage = 0;
  let prevPage = '', nextPage = '';
  let pageUrl = '';

  if (isBrowser) {
    origin = window.location.origin;
    pathName = window.location.pathname;

    const pathNameSplit = pathName.split('/');
    pageUrl = `/${pathNameSplit[1]}/${pathNameSplit[2]}/`;

    if (count) {
      const activitiesPerPage = 15;
      numPages = Math.ceil(count / activitiesPerPage);
      currentPage = pathNameSplit[3] === '' ? 0 : parseInt(pathNameSplit[3]);

      isFirst = currentPage === 0;
      isLast = currentPage === numPages - 1;
      prevPage = currentPage - 1 === 0 ? `${origin}${pageUrl}` : `${origin}${pageUrl}${(currentPage - 1).toString()}`;
      nextPage = `${origin}${pageUrl}${(currentPage + 1).toString()}`
    }
  }

  let dates: any = [];
  (results || []).map((elem: any) => {
    let date = new Date(elem.created);
    dates.push(date.toLocaleString(elem.langcode, { dateStyle: 'medium' }));
  });
  return (
    <WithCenteredContent>
      <StyledActivitySummary>
        {(sectionTitle || buttonLinkText) && (
          <div className='section__header'>
            {sectionTitle && <div className='section__left-side-text' dangerouslySetInnerHTML={{ __html: sectionTitle }} />}
            {buttonLinkText && (
              <div className='section__view-more section__view-more--desktop'>
                <Button
                  backgroundColor={ButtonBackgroundTypes.TRANSPARENT}
                  border='var(--theming-main-blue)'
                  color='var(--theming-main-blue)'
                  onClick={() => navigate(headerRightSideBtnUri)}
                >
                  <>{buttonLinkText}</>
                </Button>
              </div>
            )}
          </div>
        )}

        <div className='section__cards'>
          {(results || []).map((card: any, index: number) => (
            <div className='card' key={`${index}sectionCards`}>
              {/*  todo: Use the actual 'alt' attribute of the entity */}
              <Link to={card?.view_node}>
                <Img
                  fixed={activityFeatureImage[index]?.childImageSharp?.fixed}
                  alt={card?.title || ''}
                  className='card__image'
                  style={{
                    width: '100%',
                  }}
                />
              </Link>
              <div className='card__description'>
                {card?.title && <div className='card__title' dangerouslySetInnerHTML={{ __html: card?.title }} />}
                {card?.created && <div className='card__date' dangerouslySetInnerHTML={{ __html: dates[index] }} />}
                {card?.view_node && (
                  <div
                    className='card__view-more'
                    dangerouslySetInnerHTML={{ __html: card?.view_more }}
                    onClick={() => navigate(card?.view_node)}
                  />
                )}
              </div>
            </div>
          ))}
        </div>

        {headerRightSideBtnText && (
          <div className='section__view-more section__view-more--mobile'>
            <Button
              backgroundColor={ButtonBackgroundTypes.TRANSPARENT}
              border='var(--theming-main-blue)'
              color='var(--theming-main-blue)'
              onClick={() => navigate(headerRightSideBtnUri)}
            >
              <>{headerRightSideBtnText}</>
            </Button>
          </div>
        )}
        {isBrowser && count && (
          <ul className='section__pagination pagination justify-content-center'>
            {!isFirst && (
              <li className='page-item'>
                <Link className='page-link' to={prevPage} rel="prev">
                  &laquo;
                </Link>
              </li>
            )}
            {Array.from({ length: numPages }, (_, i) => (
              <li className={`page-item ${currentPage === i ? 'active': ''}`}>
                <Link className='page-link' key={`pagination-number${i}`} to={`${origin}${pageUrl}${i === 0 ? "" : i}`}>
                  {i + 1}
                </Link>
              </li>
            ))}
            {!isLast && (
              <li className='page-item'>
                <Link className='page-link' to={nextPage} rel="next">
                  &raquo;
                </Link>
              </li>
            )}
          </ul>
        )}
      </StyledActivitySummary>
    </WithCenteredContent>
  );
};

export default ActivitySummary;

export const fragment = graphql`
  fragment ParagraphActivitiesGraphql1ViewResult on DRUPAL_ActivitiesAssistGraphql1ViewResult {
    type: __typename
    results {
      created
      title
      field_featured_image
      view_node
      view_more
      langcode
    }
    imageGatsbyFile {
      childImageSharp {
        fixed(width: 383, height: 273, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
  }
  fragment ParagraphActivitiesGraphql2ViewResult on DRUPAL_ActivitiesAssistGraphql2ViewResult {
    type: __typename
    count
    results {
      created
      title
      field_featured_image
      view_node
      view_more
      langcode
    }
    imageGatsbyFile2 {
      childImageSharp {
        fixed(width: 383, height: 273, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
  }
`;
