import React from 'react';
import StyledSimpleInput from './StyledSimpleInput';

interface SimpleInputProps {
  value: string;
  onChange: (newValue: string) => void;
  customWidth?: string;
  customHeight?: string;
}

const SimpleInput = ({ value, onChange, customWidth, customHeight }: SimpleInputProps) => (
  <StyledSimpleInput
    customWidth={customWidth}
    customHeight={customHeight}
    value={value}
    onChange={e => onChange((e.target as any).value)}
  />
);

export default SimpleInput;
