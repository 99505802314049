import { graphql } from 'gatsby';
import React from 'react';
import ImageCarousel from '../../drupalSections/ImagesCarousel/imageCarousel';

const MultipleImagesParagraph = ({ node }: any) => {
  const { fieldImages, multipleImageGatsbyFile } = node;

  return <ImageCarousel fieldImages={fieldImages} multipleImages={multipleImageGatsbyFile} />;
};

export default MultipleImagesParagraph;

export const fragment = graphql`
  fragment ParagraphMultipleImages on DRUPAL_ParagraphMultipleImagesParagraph {
    id
    type: entityBundle
    fieldSpacingAfter
    fieldImages {
      alt
      entity {
        uri {
          url
        }
      }
    }
    multipleImageGatsbyFile {
      childImageSharp {
        fluid(quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
  }
`;
