import styled from 'styled-components';
import { cssMobileFirstBreakpoints } from '../../constants/mediaBreakpoints';

const StyledImageCarousel = styled.div`
  overflow: hidden;
  .carousel {
    border-radius: 16px;
    display: flex;
    align-items: center;
    user-select: none;
    margin: 0 -24px;

    &__img {
      width: 100%;
      border-radius: 16px;
      height: 50vw;
      object-fit: cover;
      cursor: pointer;
      min-width: 1px;
    }

    &__button--prev,
    &__button--next {
      position: relative;
      display: flex;
      flex: 0 0 auto;
      justify-content: center;
      z-index: 2;
      width: 24px;
      height: 24px;
      border-radius: 4px;
      background-color: white;
      border: 1px solid #ddd;
      font-size: 28px;
      line-height: 24px;
      cursor: pointer;
      user-select: none;
    }

    & > .carousel__button--next {
      right: 26px;
    }

    & > .carousel__button--prev {
      left: 26px;
    }
  }

  .lightbox {
    user-select: none;
    z-index: 9;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);

    &__container {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;
    }

    &__img {
      width: 70%;
      object-fit: cover;
      filter: drop-shadow(0px 4px 64px rgba(0, 0, 0, 0.4));
      border-radius: 16px;
    }

    & > .carousel__button--next {
      right: -6px;
    }

    & > .carousel__button--prev {
      left: -6px;
    }
  }

  .swiper-button-disabled {
    color: grey;
    background-color: #d9d9d9;
    border: 1px solid #d9d9d9;
  }

  @media only screen and (${cssMobileFirstBreakpoints.mobileAndMore}) {
    .carousel__img {
      height: 20vw;
    }
  }

  @media only screen and (${cssMobileFirstBreakpoints.desktopAndMore}) {
    .carousel__img {
      height: 273px;
    }
  }

  @media only screen and (${cssMobileFirstBreakpoints.largest}) {
    .lightbox__img {
      width: 52vw;
    }
  }
`;

export default StyledImageCarousel;
