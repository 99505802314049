import { Dropdown } from 'react-bootstrap';
import styled from 'styled-components';

const StyledLanguagePicker = styled.div`
  .dropdown {
    &__selected {
      margin-top: 5px;
    }

    &__caret {
      margin-left: 5.6px;
      margin-top: 7.5px;
    }
  }

  .dropdown-toggle::after {
    display: none !important;
  }
`;

const StyledDropdown = styled(Dropdown)`
  && {
    width: 47px;
  }
  &.show .dropdown__caret {
    transform: rotate(180deg);
  }
`;

const StyledDropdownMenu = styled(Dropdown.Menu)`
  && {
    min-width: 47px;
  }
`;

export { StyledDropdownMenu, StyledLanguagePicker, StyledDropdown };
