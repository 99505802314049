import { Container, Popover } from 'react-bootstrap';
import styled from 'styled-components';
import { cssBreakpoints } from '../../../constants/mediaBreakpoints';

const desktopWidthPanelHeight = '188px';
const mobileWidthPanelHeight = '90px';
const mobilePanelMargin = '11px';
const desktopPanelMargin = '150px';
const wideDesktopPanelMargin = '20vw';

const StyledPopover = styled(Popover)`
  && {
    z-index: 9999;
    background: #f8f8f8;
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    width: 680px;
    height: 444px;

    margin-bottom: -89px;
    margin-left: -78px;

    .popover {
      &__close {
        position: absolute;
        top: 24px;
        right: 24px;
        cursor: pointer;
      }

      &__content {
        margin-left: 47px;
      }

      &__title {
        margin-top: 82px;
        color: var(--theming-main-blue);
        font-weight: 600;
        font-size: 32px;
      }

      &__description {
        margin-top: 20px;
        font-size: 22px;
        color: var(--theming-footer-gray);
      }
      &__input {
        width: 520px;
        height: 48px;        
      }
      &__message_container{
        width: 100%;
        height: 39px;
        display: block;
        padding-top: 13px;
      }
      &__error{
        color: red;
      }
      &__confirm{
        color: green;
      }
      &__show_message{
        display: block;
      }
      &__hide_message{
        display: none;
      }
    }

    @media only screen and (${cssBreakpoints.mediumAndLess}) {
      width: 329px;
      height: 370px;
      .popover {
        &__close {
          top: 16px;
          right: 16px;
          width: 24px;
          height: 24px;
        }
        &__content {
          margin-left: 24px;
          line-height: 16px;
          button{
            margin-top: 10px;
          }
        }
        &__title {
          margin-top: 48px;

          font-size: 24px;
          line-height: 36px;
          font-weight: 600;
        }
        &__description {
          font-size: 14px;
          margin-top: 16px;
        }
        &__input {
          margin: 0px 0px 5px 0px;
          width: 281px;
          height: 35px;
          span{
            font-size: 14px; 
          }
        }
        &__message_container{
          width: 100%;
          height: 30px;
          display: block;
          padding-top: 0px;
          font-size: 13px;
        }
      }
    }
    @media only screen and (${cssBreakpoints.extraSmall}) {
      margin-left: -20px;
    }
  }
`;

const StyledFooter = styled.footer <{ isDonatePage?: boolean }>`
  && {
    position: relative;
    margin-top: calc(${desktopWidthPanelHeight} / 2);
    background: var(--theming-footer-gray);
    min-height: 584px;
    width: 100%;
    color: white;
    font-size: 13px;
    padding: 0px 24px;
  }

  .news-letter {
    position: absolute;
    top: -115px;
    right: 88px;
    z-index: 4;

    &__trigger {
      cursor: pointer;
      padding-top: 23px;
      text-align: center;
      width: 84px;
      height: 84px;
      background: #f8f8f8;
      color: blue;

      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
      border-radius: 12.69px;
    }

    &__text {
      line-height: 18px;
      margin-top: 4px;
      font-weight: 600;
      font-size: 12px;
    }

    &__content {
    }
  }
  /* prevent intersection with panel */
  @media only screen and (max-width: 1375px) {
    .news-letter {
      top: -200px;
    }
  }

  @media only screen and (max-width: 1100px) {
    .news-letter {
      right: 25px;
    }
  }
  @media only screen and (${cssBreakpoints.extraSmall}) {
    .news-letter {
      position: fixed;
      top: calc(100vh - 110px);
    }
  }

  .donate-panel {
    // center panel
    position: absolute;
    left: 50%;

    top: calc(-${desktopWidthPanelHeight} / 2);
    transform: translate(-50%, 0);

    background: var(--theming-main-blue);
    border-radius: 16px;
    text-align: center;
    width: 100%;
    max-width: min(var(--size-desktop-width), calc(100% - ${desktopPanelMargin} * 2));
    height: calc(${desktopWidthPanelHeight});

    display: flex;
    padding: 0px 103px;
    justify-content: space-between;
    align-items: center;

    &__text {
      font-weight: 600;
      font-size: 22px;
      min-width: 207px;
      max-width: 486px;
      text-align: left;
      padding-right: 10px;
      p{
        margin: 0px;
      }
    }

    &__donate {
    }
  }
  .footer {
    &__logo {
      margin-bottom: 23px;
    }

    &__list {
      & > p {
        margin-bottom: 0;
      }
    }

    &__anchor-row {
      text-decoration: none;
      margin-bottom: 6px;
      color: #adadad;
    }

    &__section-first {
      margin-top: ${({ isDonatePage }) => isDonatePage ? '100px' : '184px'};
    }
    &__section {
      margin-top: ${({ isDonatePage }) => isDonatePage ? '225px' : '312px'};
    }

    &__inline {
      display: flex;
      align-items: center;
    }

    &__grayed-row {
      margin-bottom: 10px;
      & > a {
        text-decoration: none;
      }
      color: var(--theming-text-gray);
    }
    &__bold-row {
      font-weight: 600;
    }

    &__margin-top {
      margin-top: 10px;
      &--large {
        margin-top: 16px;
      }
    }
    &__margin-left {
      margin-left: 10px;
    }
    &__margin-bottom {
      margin-bottom: 12px;
    }

    &__section-title {
      font-size: 15px;
      margin-bottom: 16px;
    }
    &__social-media-icon {
      margin-right: 23px;
    }
  }

  @media only screen and (${cssBreakpoints.extraSmall}) {
    && {
      padding-bottom: 30px;
    }

    .donate-panel {
      font-size: 14px;
      padding: 0px 13px;
      height: calc(${mobileWidthPanelHeight});
      top: calc(-${mobileWidthPanelHeight} / 2);

      &__text {
        font-size: 14px;
        line-height: 21px;
      }
    }

    .footer {
      &__section-first {
        margin-top: 85px;
      }
      &__section {
        margin-top: 9px;
      }

      &__logo {
        width: 119px;
        height: 40px;
      }
    }
  }

  @media only screen and (${cssBreakpoints.mobileAndTablet}) {
    .donate-panel {
      max-width: min(var(--size-desktop-width), calc(100% - ${mobilePanelMargin} * 2));
    }
  }

  @media only screen and (${cssBreakpoints.largeOnly}) {
    .donate-panel {
      max-width: min(var(--size-desktop-width), calc(100% - ${wideDesktopPanelMargin} * 2));
    }
  }
`;

const StyledContainer = styled(Container)`
  && {
    margin: 0px auto;
    max-width: var(--size-desktop-width);
  }
`;

export { StyledFooter, StyledContainer, StyledPopover };
