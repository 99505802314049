import { graphql } from 'gatsby';
import React, {useState} from 'react';
import StyledFaq from './styledFaq';
import WithCenteredContent from "../../HOCs/WithCenteredContent/WithCenteredContent";
import Img from "gatsby-image";
const Faq = ({ sectionTitle, cardData }: any) => {
   const {results} = cardData;
    function toggleAnswer(event: any) {
        const classList = event.target.classList;

        if (!classList.contains('faq__question')) {
            const question = event.target.parentElement;
            if (question && question.classList) {
                question.classList.toggle('show');
            }
        }
    }
    function FAQItem({ question, answer }: any) {

        return (
            <div className="faq__item">
                <div className="faq__question" onClick={toggleAnswer} dangerouslySetInnerHTML={{ __html: question + '<span class="icon">⌵</span>' }} />
                <div className="faq__answer" dangerouslySetInnerHTML={{ __html: answer }} />
            </div>

        );
    }
    return (
        <StyledFaq>
            <WithCenteredContent>
                <>
                    {sectionTitle && <div className='section__title' dangerouslySetInnerHTML={{ __html: sectionTitle }} />}
                    <div className='section__faq'>
                    {(results || []).map((result: any, index: number) => (
                            <FAQItem
                                question={result.field_question}
                                answer={result.body}
                                key={`${index}`}
                            />
                    ))}
                    </div>
                </>
            </WithCenteredContent>
        </StyledFaq>

    );
};

export default Faq;
export const fragment = graphql`
  fragment ParagraphFaqGraphql1ViewResult on DRUPAL_FaqAssistGraphql1ViewResult {
    type: __typename
    results {
      title
      field_question
      body
    }
  }
`;