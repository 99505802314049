import styled from 'styled-components';
import { cssBreakpoints } from '../../constants/mediaBreakpoints';

const StyledLayout = styled.div`
  && {
    font-family: 'Poppins';
    font-weight: 400;

    .title {
      text-align: center;
      margin-bottom: 20px;
      margin-top: 100px;
    }
  }

  .layout-social-media {
    text-align: center;
    margin-bottom: 50px;

    &__image {
      width: 22px;
      margin-right: 15px;
    }

    &--mobile {
      display: block;
    }

    &--desktop {
      display: none;
    }
  }

  @media only screen and (min-width: 1209px) {
    && {
      .title {
        margin-bottom: 50px;
        margin-top: 100px;
      }
    }
    .layout-social-media {
      z-index: 4;
      position: absolute;
      top: 164px;
      width: 40px;

      height: fit-content;
      background: var(--theming-black);
      border-radius: 0px 5px 5px 0px;

      &__title {
        color: white;
        font-size: 10px;
        margin: 10px 0px;
        text-align: center;
      }

      &__image {
        margin: 0px 10px;
        margin-bottom: 12px;
      }

      &--mobile {
        display: none;
      }

      &--desktop {
        display: initial;
      }
    }
  }

  @media only screen and (${cssBreakpoints.mediumMobileOnly}) {
    && {
      margin-top: 100px;
    }
  }
`;

export default StyledLayout;
