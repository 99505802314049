import * as React from 'react';
import StyledLayout from './styledLayout';
import Footer from './footer';
import Header from './header';
import Seo from '../seo';

import '../../styles/variables.css';
import '../../styles/drupalClasses.css';
import { StaticImage } from 'gatsby-plugin-image';
import WithCenteredContent from '../../HOCs/WithCenteredContent/WithCenteredContent';

interface LayoutProps {
  children: React.ReactNode;
  language: string;
  pathEn: string;
  pathRo: string;
  title: string;
  metaTags: MetaTagsData[];
  hasSocialMediaShareIcons?: boolean;
  siteUrl?: any;
  isDonatePage?: boolean;
  ogImage?: string;
  type?:string;
}

interface MetaTagsData {
  key: string;
  value: string;
}

const SocialMediaIconsDesktop = ({ siteUrl, title, metaTags }: any) => {
  const url = metaTags.filter((elem: any) => {
    return elem.key === 'canonical';
  })[0].value;
  const link = encodeURIComponent(`${siteUrl}/${url}`);

  return (
    <>
      <a rel='noreferrer' href={`https://twitter.com/intent/tweet?text=${title}&url=${link}`} target='_blank'>
        <StaticImage
          className='layout-social-media__image'
          src='../../images/svg/social-media/twitter-squared.svg'
          alt='Share on twitter'
        />
      </a>
      <a rel='noreferrer' href={`https://www.facebook.com/sharer/sharer.php?u=${link}`} target='_blank'>
        <StaticImage
          className='layout-social-media__image'
          src='../../images/svg/social-media/facebook-squared.svg'
          alt='Share on facebook'
        />
      </a>
      <a rel='noreferrer' href={`https://www.linkedin.com/cws/share?url=${link}`} target='_blank'>
        <StaticImage
          className='layout-social-media__image'
          src='../../images/svg/social-media/linkedin-squared.svg'
          alt='Share on linkedin'
        />
      </a>
    </>
  );
};

const SocialMediaIconsMobile = ({ siteUrl, title, metaTags }: any) => {
  const url = metaTags.filter((elem: any) => {
    return elem.key === 'canonical';
  })[0].value;
  const link = encodeURIComponent(`${siteUrl}/${url}`);
  return (
    <>
      <a rel='noreferrer' href={`https://twitter.com/intent/tweet?text=${title}&url=${link}`} target='_blank'>
        <StaticImage
          className='layout-social-media__image'
          src='../../images/svg/social-media/twitter-squared-black.svg'
          alt='Share on twitter'
        />
      </a>
      <a rel='noreferrer' href={`https://www.facebook.com/sharer/sharer.php?u=${link}`} target='_blank'>
        <StaticImage
          className='layout-social-media__image'
          src='../../images/svg/social-media/facebook-squared-black.svg'
          alt='Share on facebook'
        />
      </a>
      <a rel='noreferrer' href={`https://www.linkedin.com/cws/share?url=${link}`} target='_blank'>
        <StaticImage
          className='layout-social-media__image'
          src='../../images/svg/social-media/linkedin-squared-black.svg'
          alt='Share on linkedin'
        />
      </a>
    </>
  );
};

const Layout = ({
  children,
  language,
  pathEn,
  pathRo,
  title,
  metaTags,
  hasSocialMediaShareIcons,
  siteUrl,
  isDonatePage,
  ogImage,
  type,
}: LayoutProps) => {
    const showingTitle = type==='activityPage' && title;
  return (

    <StyledLayout>
      {hasSocialMediaShareIcons && (
        <div className='layout-social-media layout-social-media--desktop'>
          <div>
            <div className='layout-social-media__title'>Share</div>
            <SocialMediaIconsDesktop siteUrl={siteUrl} title={title} metaTags={metaTags} />
          </div>
        </div>
      )}
      <Seo title={title} lang={language} metatags={metaTags} ogImage={ogImage} />
      <Header language={language} pathEn={pathEn} pathRo={pathRo} />

      {showingTitle && (
        <WithCenteredContent hasSmallCenteredContent>
          <h1 className='title'>{title}</h1>
        </WithCenteredContent>
      )}

      {hasSocialMediaShareIcons && (
        <div className='layout-social-media layout-social-media--mobile'>
          <SocialMediaIconsMobile siteUrl={siteUrl} title={title} metaTags={metaTags} />
        </div>
      )}

      {children}
      <Footer language={language} isDonatePage={isDonatePage} />
    </StyledLayout>
  );
};

export default Layout;
