import { graphql } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import React from 'react';
import DonateCenteredImage from '../../drupalSections/DonateCenteredContentImage/donateCenteredContentImage';
import HeaderImageCarousel from '../../drupalSections/HeaderImagesCarousel/headerImageCarousel';
import TransparentAvatarLeftSideContent from '../../drupalSections/TransparentAvatarLeftSideContent/transparentAvatarLeftSideContent';

const sections: any = {
  header: HeaderImageCarousel,
  donate: DonateCenteredImage,
  mission: TransparentAvatarLeftSideContent,
};

export const GenericParagraph = ({ node }: any) => {
  const { fieldTypeGenericParagraph } = node;

  const {
    desktopImage,
    fieldDesktopImage,
    fieldLinkImages,
    mobileImage,
    fieldMobileImage,
    fieldMainText,
    fieldSecondaryText,
    fieldLink,
    fieldIsDonateLink,
  } = node;

  const CurrentSection = sections[fieldTypeGenericParagraph];

  const processedDesktopImages = (desktopImage || []).map((image: GatsbyImage, index: number) => ({
    image,
    metadata: fieldDesktopImage[index],
  }));
  /* const processedMobileImages = (mobileImage || []).map((image: GatsbyImage, index: number) => ({
    image,
    metadata: fieldMobileImage[index],
  })); */

  return (
    <CurrentSection
      desktopImages={processedDesktopImages}
      linkImages={fieldLinkImages}
      //mobileImages={processedDesktopImages}
      title={fieldMainText?.processed}
      description={fieldSecondaryText?.processed}
      button={{
        ref: fieldLink?.[0]?.url?.path,
        buttonText: fieldLink?.[0]?.title,
        isDonate: fieldIsDonateLink?.[0],
      }}
    />
  );
};

export const fragment = graphql`
  fragment ParagraphGeneric on DRUPAL_ParagraphGenericParagraph {
    id
    type: entityBundle
    fieldSpacingAfter
    desktopImage {
      childImageSharp {
        fluid(maxWidth: 1900, quality: 75) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
          presentationWidth
        }
      }
    }
    mobileImage {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
          presentationWidth
        }
      }
    }
    fieldDesktopImage {
      alt
      entity {
        uri {
          url
        }
      }
    }
    fieldLinkImages {
      uri
      title
      url {
        path
      }
    }
    fieldLink {
      uri
      title
      url {
        path
      }
    }
    fieldIsDonateLink
    fieldMainText {
      processed
    }
    fieldMobileImage {
      alt
      entity {
        uri {
          url
        }
      }
    }
    fieldSecondaryText {
      processed
    }
    fieldTypeGenericParagraph
  }
`;
