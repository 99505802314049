import { graphql, Link, useStaticQuery } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React, { useState } from 'react';
import { Col, OverlayTrigger, Row } from 'react-bootstrap';
import CustomButton from '../../button';
import { ButtonBackgroundTypes } from '../../button/button';
import SimpleInput from '../../simpleInput/SimpleInput';
import { StyledContainer, StyledFooter, StyledPopover } from './styledFooter';
import { navigate } from 'gatsby';
import Img from 'gatsby-image';
import CookieConsent from 'react-cookie-consent';
import { useLocation } from '@reach/router'; // this helps tracking the location
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';
import axios from 'axios';
import SimpleCheckbox from '../../simpleCheckbox/SimpleCheckbox';

const Footer = ({ language, isDonatePage }: any) => {
  const data = useStaticQuery(graphql`
    query {
      drupal {
        blockContentQuery {
          en: entities(language: EN) {
            ... on DRUPAL_BlockContentFooter {
              entityId
              entityLabel
              entityBundle
              fieldCtaText {
                processed
              }
              fieldCtaLink {
                uri
                title
                url {
                  path
                }
              }
              fieldLogo {
                alt
                entity {
                  uri {
                    url
                  }
                }
              }
              fieldContactIcons {
                alt
                entity {
                  uri {
                    url
                  }
                }
              }
              fieldContactItems
              fieldContactItemsType
              fieldFoundationAddress {
                processed
              }
              fieldAccountTitle
              fieldAccountDetails {
                processed
              }
              fieldTocTitle
              fieldTocLinks {
                uri
                title
                url {
                  path
                }
              }
              fieldSocialTitle
              fieldSocialIcons {
                alt
                entity {
                  uri {
                    url
                  }
                }
              }
              fieldSocialLinks {
                uri
                title
                url {
                  path
                }
              }
              footerLogo {
                childImageSharp {
                  fixed(width: 193, height: 66) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
              contactIcons {
                childImageSharp {
                  fixed(width: 16, height: 16) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
              socialIcons {
                childImageSharp {
                  fixed(width: 26, height: 26) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
              fieldGdprText {
                processed
              }
              fieldGdprAcceptText
              fieldGdprRefuseText
              fieldNewsletterTitle
              fieldNewsletterSubtitle
              fieldNewsletterSubmitLink
              fieldNewsletterSubmitButton
              fieldNewsletterMessageSuccess
              fieldNewsletterMessageError
              fieldNewsletterMessageErrorG
              fieldNewsletterMessageGdpr
            }
          }
          ro: entities(language: RO) {
            ... on DRUPAL_BlockContentFooter {
              entityId
              entityLabel
              entityBundle
              fieldCtaText {
                processed
              }
              fieldCtaLink {
                uri
                title
                url {
                  path
                }
              }
              fieldLogo {
                alt
                entity {
                  uri {
                    url
                  }
                }
              }
              fieldContactIcons {
                alt
                entity {
                  uri {
                    url
                  }
                }
              }
              fieldContactItems
              fieldContactItemsType
              fieldFoundationAddress {
                processed
              }
              fieldAccountTitle
              fieldAccountDetails {
                processed
              }
              fieldTocTitle
              fieldTocLinks {
                uri
                title
                url {
                  path
                }
              }
              fieldSocialTitle
              fieldSocialIcons {
                alt
                entity {
                  uri {
                    url
                  }
                }
              }
              fieldSocialLinks {
                uri
                title
                url {
                  path
                }
              }
              footerLogo {
                childImageSharp {
                  fixed(width: 193, height: 66) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
              contactIcons {
                childImageSharp {
                  fixed(width: 16, height: 16) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
              socialIcons {
                childImageSharp {
                  fixed(width: 26, height: 26) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
              fieldGdprText {
                processed
              }
              fieldGdprAcceptText
              fieldGdprRefuseText
              fieldNewsletterTitle
              fieldNewsletterSubtitle
              fieldNewsletterSubmitLink
              fieldNewsletterSubmitButton
              fieldNewsletterMessageSuccess
              fieldNewsletterMessageError
              fieldNewsletterMessageErrorG
              fieldNewsletterMessageGdpr
            }
          }
        }
      }
    }
  `);

  const footerBlock = data.drupal.blockContentQuery[language][0];

  const PopoverContent = ({ props }: any) => {
    const [newsLetterEmail, setNewsLetterEmail] = useState('');
    const [newsletterGdpr, setNewsletterGdpr] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [showConfirmMessage, setShowConfirmMessage] = useState(false);
    const [showErrorMessageGdpr, setShowErrorMessageGdpr] = useState(false);

    const submitNewsletter = async () => {
      const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (!newsLetterEmail || reg.test(newsLetterEmail) === false) {
        setShowConfirmMessage(false);
        setShowErrorMessage(true);
      } else {
        if (newsletterGdpr === false) {
          setShowErrorMessageGdpr(true);
          setShowErrorMessage(false);
        } else {
          const response = await axios({
            method: 'POST',
            url: footerBlock.fieldNewsletterSubmitLink,
            data: { field_0: newsLetterEmail },
            headers: { 'Content-Type': 'application/json' },
          });
          setNewsLetterEmail('');
          setShowConfirmMessage(true);
          setShowErrorMessage(false);
          setShowErrorMessageGdpr(false);
        }
      }
    };

    const hidePopup = () => {
      setNewsLetterEmail('');
      setShowConfirmMessage(false);
      setShowErrorMessage(false);
      document.body.click();
    };

    const modifyNewsletterGdpr = () => {
      setNewsletterGdpr(!newsletterGdpr);
      if (newsletterGdpr) {
        setShowErrorMessageGdpr(false);
      }
    };

    const location = useLocation();
    initializeAndTrack(location);

    if (!props.show) {
      return null;
    }

    return (
      <StyledPopover arrowProps={{ style: { display: 'none' } }} id='popover-basic' bsPrefix='news-letter__content' {...props}>
        <StaticImage
          onClick={hidePopup}
          className='popover__close'
          src='../../../images/svg/close.svg'
          alt='close'
          width={40}
          height={40}
        />
        <div className='popover__content'>
          <div className='popover__title'>{footerBlock.fieldNewsletterTitle}</div>
          <div className='popover__description'>{footerBlock.fieldNewsletterSubtitle}</div>

          <div className='popover__message_container'>
            <div className={`popover__error ${showErrorMessage ? 'popover__show_message' : 'popover__hide_message'}`}>
              {footerBlock.fieldNewsletterMessageError}
            </div>
            <div className={`popover__confirm ${showConfirmMessage ? 'popover__show_message' : 'popover__hide_message'}`}>
              {footerBlock.fieldNewsletterMessageSuccess}
            </div>
          </div>

          <div className='popover__input'>
            <SimpleInput value={newsLetterEmail} onChange={setNewsLetterEmail} />
          </div>
          <div className='popover__message_container'>
            <div className={`popover__error ${showErrorMessageGdpr ? 'popover__show_message' : 'popover__hide_message'}`}>
              {footerBlock.fieldNewsletterMessageErrorG}
            </div>
          </div>
          <div className='popover__input'>
            <SimpleCheckbox checked={newsletterGdpr} onChange={modifyNewsletterGdpr} />
            <span dangerouslySetInnerHTML={{ __html: footerBlock.fieldNewsletterMessageGdpr }} />
          </div>

          <CustomButton
            color='var(--theming-main-blue)'
            onClick={submitNewsletter}
            backgroundColor={ButtonBackgroundTypes.WHITE}
            border='var(--theming-main-blue)'
          >
            <>{footerBlock.fieldNewsletterSubmitButton}</>
          </CustomButton>
        </div>
      </StyledPopover>
    );
  };

  return (
    <StyledFooter className='footer' isDonatePage={isDonatePage}>
      <div className='news-letter'>
        <OverlayTrigger trigger='click' placement='top-start' overlay={props => <PopoverContent props={props} />} rootClose>
          <div className='news-letter__trigger'>
            <StaticImage src='../../../images/svg/email-blue.svg' alt='ASSIST logo' width={24} height={24} />
            <div className='news-letter__text'>Newsletter</div>
          </div>
        </OverlayTrigger>
      </div>
      {!isDonatePage && (
        <div className='donate-panel'>
          <span className='donate-panel__text' dangerouslySetInnerHTML={{ __html: footerBlock?.fieldCtaText.processed }} />
          <CustomButton
            color='var(--theming-main-blue)'
            backgroundColor={ButtonBackgroundTypes.WHITE}
            onClick={() => navigate(footerBlock.fieldCtaLink.url.path)}
          >
            <>
              <StaticImage
                style={{
                  marginRight: '10px',
                  marginTop: '4px',
                }}
                src='../../../images/svg/blue-heart.svg'
                alt='Heart'
              />
              {footerBlock?.fieldCtaLink.title}
            </>
          </CustomButton>
        </div>
      )}
      <StyledContainer fluid>
        <Row>
          <Col sm={6} md={3} xs={12} className='footer__section-first'>
            <Img fixed={footerBlock?.footerLogo?.childImageSharp?.fixed} alt={footerBlock?.fieldLogo?.alt || ''} className='footer__logo' />

            {footerBlock?.contactIcons.map((icon: any, index: number) => (
              <div className='footer__inline footer__grayed-row' key={index + 'contactIcons'}>
                <Img fixed={icon?.childImageSharp?.fixed} alt={footerBlock.fieldContactIcons?.[index]?.alt || ''} />

                {footerBlock.fieldContactItemsType[index] === 'mailto' && (
                  <a className='footer__anchor-row footer__margin-left' href={`mailto: ${footerBlock.fieldContactItems[index]}`}>
                    {footerBlock.fieldContactItems[index]}
                  </a>
                )}

                {footerBlock.fieldContactItemsType[index] === 'tel' && (
                  <a className='footer__anchor-row footer__margin-left' href={`tel: ${footerBlock.fieldContactItems[index]}`}>
                    {footerBlock.fieldContactItems[index]}
                  </a>
                )}
              </div>
            ))}

            <div className='footer__margin-top'>
              <div className='footer__list' dangerouslySetInnerHTML={{ __html: footerBlock?.fieldFoundationAddress.processed }} />
            </div>
          </Col>
          <Col sm={6} md={3} xs={12} className='footer__section d-flex justify-content-md-center'>
            <div>
              <div className='footer__bold-row footer__section-title'>{footerBlock?.fieldAccountTitle}</div>
              <div className='footer__list' dangerouslySetInnerHTML={{ __html: footerBlock?.fieldAccountDetails.processed }} />
            </div>
          </Col>
          <Col sm={6} md={3} xs={12} className='footer__section d-flex justify-content-md-center'>
            <div>
              <div className='footer__bold-row footer__section-title '>{footerBlock?.fieldTocTitle}</div>
              {footerBlock?.fieldTocLinks.map((link: any, index: number) => (
                <div className='footer__margin-bottom' key={`${index}tocLinks`}>
                  <Link className='footer__anchor-row' to={link.url.path}>
                    {link.title}
                  </Link>
                </div>
              ))}
            </div>
          </Col>
          <Col sm={6} md={3} xs={12} className='footer__section  d-flex justify-content-md-end'>
            <div>
              <div className='footer__bold-row footer__section-title'>{footerBlock?.fieldSocialTitle}</div>
              <div className='footer__inline'>
                {footerBlock?.socialIcons.map((icon: any, index: number) => (
                  <a rel='noreferrer' href={footerBlock?.fieldSocialLinks?.[index]?.url.path} target='_blank' key={`${index}socialIcons`}>
                    <Img
                      fixed={icon?.childImageSharp?.fixed}
                      alt={footerBlock.fieldSocialIcons?.[index]?.alt || ''}
                      className='footer__social-media-icon'
                    />
                  </a>
                ))}
              </div>
            </div>
          </Col>
        </Row>
        <CookieConsent
          location='bottom'
          buttonText={footerBlock?.fieldGdprAcceptText}
          enableDeclineButton
          flipButtons
          declineButtonText={footerBlock?.fieldGdprRefuseText}
          cookieName='gatsby-gdpr-google-analytics'
        >
          <div dangerouslySetInnerHTML={{ __html: footerBlock?.fieldGdprText?.processed }}></div>
        </CookieConsent>
      </StyledContainer>
    </StyledFooter>
  );
};

export default Footer;
