import { graphql } from 'gatsby';
import React from 'react';
import Img from 'gatsby-image';
import { BsCloudDownloadFill } from 'react-icons/bs';

import WithCenteredContent from '../../HOCs/WithCenteredContent/WithCenteredContent';
import { StyledBadges } from './styledBadges';

const Badges = ({ sectionTitle, cardData }: any) => {
  const { results, badgeImageGatsbyFileSmall, badgeImageGatsbyFileOriginal } = cardData || {};
  return (
    <WithCenteredContent>
      <StyledBadges>
        {sectionTitle && <h3 className='section-title' dangerouslySetInnerHTML={{ __html: sectionTitle }} />}

        <div className='badges'>
          {(results || []).map((badge: any, index: number) => (
            <div className='badge-img' key={`${index}badge`}>
              <a href={badgeImageGatsbyFileOriginal[index]?.childImageSharp?.original.src} target='_blank' key={`${index}badge2`}>
                <Img
                  fluid={badgeImageGatsbyFileSmall[index]?.childImageSharp?.fluid}
                  alt={badge?.title || ''}
                  style={{
                    maxWidth: '470px',
                    width: '100%',
                    margin: '0 auto',
                  }}
                />

                <BsCloudDownloadFill className='badge-dld-icon' />
              </a>
            </div>
          ))}
        </div>
      </StyledBadges>
    </WithCenteredContent>
  );
};

export default Badges;
export const fragment = graphql`
  fragment ParagraphBadgesGraphql1ViewResult on DRUPAL_BadgesAssistGraphql1ViewResult {
    type: __typename
    results {
      title
      field_image
      field_image_1
    }
    badgeImageGatsbyFileSmall {
      childImageSharp {
        fluid(maxWidth: 470, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
          presentationWidth
        }
      }
    }
    badgeImageGatsbyFileOriginal {
      childImageSharp {
        original {
          src
        }
      }
    }
  }
`;
