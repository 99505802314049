import styled from 'styled-components';
import {cssBreakpoints, cssMobileFirstBreakpoints} from '../../constants/mediaBreakpoints';

const StyledActivitySummary = styled.div`
  && {
    margin: 0px auto;
    text-align: center;
  }
  .card {
    position: relative;
    background: #f8f8f8;
    overflow: hidden;
    width: 100%;
    min-height: 405px;
    margin-bottom: 16px;
    border-radius: 16px;

    &__description {
      line-height: 30px;
      padding: 0px 15px;
      text-align: left;
      border: none;
    }

    &__image {
      border-radius: 16px 16px 0px 0px;
    }

    &__title {
      margin-top: 5px;
      font-weight: 600;
      font-size: 20px;
      margin-bottom: 57px;
    }

    &__footer {
      margin-top: 16px;
      margin-bottom: 15px;
      display: flex;
      justify-content: space-between;
    }

    &__date {
      color: var(--theming-footer-gray);
      font-weight: 500;
      font-size: 13px;
      position: absolute;
      bottom: 21px;
    }

    &__view-more {
      cursor: pointer;
      color: var(--theming-main-blue);
      font-weight: 600;
      font-size: 15px;
      position: absolute;
      bottom: 21px;
      right: 16px;
    }
  }

  .section {
    &__cards {
      display: grid;
      justify-content: center;
      grid-template-columns: repeat(auto-fill, 100%);
      gap: 30px;
    }

    &__left-side-text {
      font-weight: 600;
      font-size: 28px;
      margin-bottom: 16px;
    }

    &__view-more {
      &--desktop {
        display: none;
      }
    }
  }

  @media only screen and (${cssMobileFirstBreakpoints.mobileAndMore}) {
    .section {
      &__header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 36px;
      }

      &__view-more {
        &--mobile {
          display: none;
        }

        &--desktop {
          display: initial;
        }
      }
      &__cards {
        grid-template-columns: repeat(auto-fill, 80%);
      }

      &__pagination {
        margin-bottom: 130px;
      }
    }

    .card {
      margin-bottom: 36px;
    }
  }

  @media only screen and (${cssMobileFirstBreakpoints.tabletAndMore}) {
    .section {
      &__cards {
        grid-template-columns: repeat(auto-fill, 40%);
      }
    }
  }

  @media only screen and (${cssMobileFirstBreakpoints.largest}) {
    .section {
      &__cards {
        grid-template-columns: repeat(auto-fill, 383px);
      }
    }
  }

  @media only screen and (${cssBreakpoints.extraSmall}) {
    .section {
      &__pagination {
        margin-bottom: 0px;
      }
    }
  }
`;

export default StyledActivitySummary;
