import styled from 'styled-components';

const StyledSimpleCheckbox = styled.input.attrs({type: 'checkbox'})`
  && {
    border: 1px solid #c4c4c4;    
    height: 15px;
    width: 15px;
    margin-right:10px;
    margin-top:5px;
  }
`;
export default StyledSimpleCheckbox;