import { graphql, useStaticQuery } from 'gatsby';
import React, { useMemo, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import Img from 'gatsby-image';

import { StyledDropdown, StyledDropdownMenu, StyledLanguagePicker } from './styledLanguagePicker';
import { StaticImage } from 'gatsby-plugin-image';

const LanguagePicker = ({ language, pathEn, pathRo }: any) => {
  const {
    allImageSharp: { nodes },
  } = useStaticQuery(graphql`
    query {
      allImageSharp {
        nodes {
          fixed {
            originalName
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  const countries: any = {
    ro: {
      code: 'ro',
      flagSrc: 'romania-flag.png',
      alt: 'Romanian',
    },
    en: {
      code: 'en',
      flagSrc: 'english-flag.png',
      alt: 'English',
    },
  };

  const paths: any = {
    ro: pathRo,
    en: pathEn
  }

  const [selectedCountry, setSelectedCountry] = useState<any>(language);
  const { code, alt } = (countries as any)[selectedCountry];

  const imagesFlags = useMemo(() => {
    const foundImages: any = {};
    const allCountriesFlagSources: any = {};

    Object.values(countries).forEach((country: any) => {
      allCountriesFlagSources[country.flagSrc] = country.code;
    });

    nodes.forEach((image: any) => {
      if (allCountriesFlagSources[image.fixed.originalName] !== undefined) {
        foundImages[allCountriesFlagSources[image.fixed.originalName]] = image.fixed;
      }
    });

    return foundImages;
  }, []);

  return (
    <StyledLanguagePicker>
      <StyledDropdown onSelect={(eventKey: any) => setSelectedCountry(eventKey)}>
        <Dropdown.Toggle variant='transparent' className='text-left'>
          <span className='dropdown__selected'>
            <Img fixed={imagesFlags[code] as any} alt={alt} />
            <StaticImage className='dropdown__caret' src='../../images/svg/dropdownCaret.svg' alt='Caret' />
          </span>
        </Dropdown.Toggle>

        <StyledDropdownMenu className='text-left'>
          {Object.values(countries).map(({ code: currentCode, alt: currentAlt }: any) => (
            <Dropdown.Item key={`${currentCode}language`} eventKey={currentCode} href={paths[currentCode]}>
              <Img fixed={imagesFlags[currentCode]} alt={currentAlt} />
            </Dropdown.Item>
          ))}
        </StyledDropdownMenu>
      </StyledDropdown>
    </StyledLanguagePicker>
  );
};

export default LanguagePicker;
