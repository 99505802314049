import styled from 'styled-components';

export const StyledSponsorsAndPartners = styled.div`
  .section-title {
    font-size: 32px;
    line-height: 1.2;
    margin-bottom: 24px;
    text-align: center;

    &:first-of-type {
      margin-top: 66px;
    }

    p {
      margin: 0;
    }
  }

  .sponsors {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 50px;
    max-width: 995px;
    margin: 0 auto 100px auto;
  }

  .sponsor-empty {
    width: 210px;
  }

  .sponsor {
    background-color: #fff;
    box-shadow: 0px 2.5641px 15.3846px rgba(0, 0, 0, 0.15);
    border-radius: 16px;
    height: 210px;
    width: 210px;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      
      &.disabled {
        pointer-events: none;
      }
    }
  }
`;
