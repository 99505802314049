import styled from 'styled-components';
import { cssMobileFirstBreakpoints } from '../../constants/mediaBreakpoints';

const StyledFaq = styled.div`
  .section {
    &__title {
      text-align: center;
      font-weight: 600;
      font-size: 24px;
    }
    
    &__faq {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      flex-direction: column;
    }
  }
  
  .faq {
    &__item {
      padding: 10px 10px 10px 0px;
      border-bottom: 1px solid #eeeeee;
    }

    &__question {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      
      p:hover {
        cursor: pointer;
        text-decoration: underline;
      }
      
      span.icon {
        cursor: pointer;
        color: #777;
        height: 42px;
        transition: transform 0.3s ease;
      }
      
    }
      
    &__answer {
      overflow: hidden;
      max-height: 0;
      transition: max-height 0.3s ease;
    }
    
    &__question.show + .faq__answer {
      max-height: 1000px;
    }

    &__question.show {
      font-weight: bold;
      span.icon {
        transform: rotate(180deg);
      }
    }
  }
  
  @media only screen and (${cssMobileFirstBreakpoints.mobileAndMore}) {
    .section {
      &__title {
        font-size: 32px;
        display: block;
        max-width: 705px;
        margin: 0px auto;
        margin-bottom: 50px;
      }
    }
  }

  @media only screen and (${cssMobileFirstBreakpoints.tabletAndMore}) {
    
  }

  @media only screen and (${cssMobileFirstBreakpoints.largest}) {

  }
`;

export default StyledFaq;
