const breakpoints = {
  small: '576px',
  medium: '768px',
  large: '992px',
  extraLarge: '1200px',
  cssCenteredContentMaxWidth: '1209px',
  extraExtraLarge: '1600px',
};

const cssBreakpoints = {
  extraSmall: 'max-width: 575px',
  mediumAndLess: 'max-width: 767px',
  mobileOnly: 'max-width: 832px',
  mobileAndTablet: 'max-width: 965px',
  mediumMobileOnly: 'min-width: 575px) and (max-width: 767px',
  tabletOnly: 'min-width: 768px) and (max-width: 992px',
  desktopOnly: 'min-width: 993px) and (max-width: 1200px',
  largeOnly: 'min-width: 1201px',
  extraLargeOnly: 'min-width: 1600px',
};

const cssMobileFirstBreakpoints = {
  mobileAndMore: 'min-width: 576px',
  tabletAndMore: 'min-width: 768px',
  desktopAndMore: 'min-width: 992px',
  largest: 'min-width: 1200px',
  extraLarge: 'min-width: 1600px',
};

const cssCustomBreakpoints = {
  ipad: 'max-width: 1090px',
  landscapePhone: 'max-width: 926px',
  iphoneX: ' min-width: 375px) and (max-width: 812px',
  iphone12ProMax: ' min-width: 428px) and (max-width: 926px',
  googlePixel: 'min-width: 830px) and (orientation: landscape',
};

export { breakpoints, cssBreakpoints, cssMobileFirstBreakpoints, cssCustomBreakpoints };
