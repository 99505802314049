import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import CustomButton from '../../components/button';
import {ButtonBackgroundTypes} from "../button/button";

const RedirectButton = ({ children, onClick }: any) => {
    return (
        <CustomButton marginLeft='10px' padding='0px 7px' width='auto' color='black'  onClick={onClick} backgroundColor={ButtonBackgroundTypes.YELLOW}>
            <>
                <StaticImage
                    style={{
                        marginRight: '10px',
                        marginTop: '4px',
                    }}
                    src='../../images/svg/black-heart.svg'
                    alt='Heart'
                />
                {children}
            </>
        </CustomButton>
    );
};

export default RedirectButton;
