import React from 'react';
import StyledWithDesktopMobileSwitch from './StyledWithDesktopMobileSwitch';

interface WithDesktopMobileSwitch {
  mobileContent?: React.ReactNode;
  desktopContent?: React.ReactNode;
}

const WithDesktopMobileSwitch = ({ mobileContent, desktopContent }: WithDesktopMobileSwitch) => (
  <StyledWithDesktopMobileSwitch>
    {/* <div className='content__mobile'>{mobileContent}</div>
    <div className='content__desktop'>{desktopContent}</div> */}
    <div>{desktopContent}</div>
  </StyledWithDesktopMobileSwitch>
);

export default WithDesktopMobileSwitch;
