import { graphql } from 'gatsby';
import React from 'react';
import TextContent from '../../drupalSections/TextContent/textContent';
import DonateForm from '../../drupalSections/Container/donateForm';
import ContactForm from '../../drupalSections/Container/contactForm';

const FormParagraph = ({ node }: any) => {
  const { fieldSelectValues, fieldTypeForm, fieldLabels, fieldTypes, fieldSubmit } = node;

  if (fieldTypeForm === 'donate') {
    return <DonateForm fields={{ fieldSelectValues, fieldLabels, fieldTypes, fieldSubmit }}></DonateForm>;
  }

  if (fieldTypeForm === 'contact') {
    return <ContactForm fields={{ fieldLabels, fieldTypes, fieldSubmit }}></ContactForm>;
  }
};

export default FormParagraph;

export const fragment = graphql`
  fragment ParagraphForm on DRUPAL_ParagraphFormParagraph {
    id
    type: entityBundle
    fieldTypeForm
    fieldLabels
    fieldTypes
    fieldRadioValues
    fieldCheckboxValues
    fieldSubmit
    fieldSelectValues
  }
`;
