import styled from 'styled-components';

export const StyledBadges = styled.div`
  .section-title {
    font-size: 32px;
    line-height: 1.3;

    max-width: 590px;
    margin-right: auto;
    margin-left: auto;

    p {
      margin: 0;
    }
  }

  .badges {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 100px;
  }

  .badge-img {
    width: 50%;
    max-width: 470px;
    position: relative;

    &:hover .badge-dld-icon {
      display: block;
    }
  }

  .badge-dld-icon {
    position: absolute;
    display: none;
    bottom: auto;
    left: 35px;
    top: 25px;
    right: auto;
    fill: #909090;
    width: 22px;
    height: 22px;
  }
`;
