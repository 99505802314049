import styled from 'styled-components';
import { cssMobileFirstBreakpoints } from '../../constants/mediaBreakpoints';

const StyledWithDesktopMobileSwitch = styled.div`
  .content {
    &__desktop {
      display: none;
    }

    &__mobile {
      display: initial;
    }
  }

  @media only screen and (${cssMobileFirstBreakpoints.mobileAndMore}) {
    .content {
      &__mobile {
        display: none;
      }
      &__desktop {
        display: initial;
      }
    }
  }
`;

export default StyledWithDesktopMobileSwitch;
