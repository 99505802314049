import * as React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

const Seo = ({ title, lang, metatags, ogImage }: any) => {
  const generatedMetaTags:
    | React.DetailedHTMLProps<React.MetaHTMLAttributes<HTMLMetaElement>, HTMLMetaElement>[]
    | { name?: string; content: any; property?: string }[]
    | undefined = [];

  const generatedLinkTags:
    | React.DetailedHTMLProps<React.MetaHTMLAttributes<HTMLLinkElement>, HTMLLinkElement>[]
    | { name?: string; href: any; rel?: string }[]
    | undefined = [];

  //query default values set in gatsby-config.js
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
          }
        }
      }
    `,
  );

  metatags.map(({ key, value }: any) => {
    switch (key) {
      case 'twitter:card':
        generatedMetaTags.push({ name: `twitter:card`, content: `summary` });
        break;

      case 'twitter:creator':
        generatedMetaTags.push({ name: `twitter:creator`, content: value });
        break;

      case 'twitter:title':
        generatedMetaTags.push({ name: `twitter:title`, content: value });
        break;

      case 'twitter:description':
        generatedMetaTags.push({ name: `twitter:title`, content: value });
        break;

      case 'description':
        generatedMetaTags.push({ name: `description`, content: value });
        break;

      case 'og:title':
        generatedMetaTags.push({ property: `og:title`, content: value });
        break;

      case 'og:url':
        let drupalUrl = new URL(value);
        generatedMetaTags.push({ property: `og:url`, content: `${site?.siteMetadata?.siteUrl}${drupalUrl.pathname}` });
        break;

      case 'canonical':
        generatedLinkTags.push({ rel: `canonical`, href: `${site?.siteMetadata?.siteUrl}/${value}` });
        break;

      case 'og:image':
        break;

      default:
        generatedMetaTags.push({ name: key, content: value });
    }
  });

  if (process.env.GOOGLE_INDEX === 'false') {
    generatedMetaTags.push({ name: `robots`, content: `noindex` });
  }

  generatedMetaTags.push({ property: `og:type`, content: `website` });
  if (ogImage) {
    generatedMetaTags.push({ property: `og:image`, content: `${site?.siteMetadata?.siteUrl}${ogImage}` });
  }

  const defaultTitle = title ? `${title} | ${site?.siteMetadata?.title}` : site?.siteMetadata?.title;
  const defaultMetaDescription = site?.siteMetadata?.description;

  if (!generatedMetaTags.some(obj => {
    return obj['name'] == 'description'
  })) {
    generatedMetaTags.push({ name: `description`, content: defaultMetaDescription });
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={defaultTitle}
      meta={generatedMetaTags}
      link={generatedLinkTags}
    />
  );
};

Seo.defaultProps = {
  lang: `ro`,
  meta: [],
  description: ``,
};

Seo.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  lang: PropTypes.string,
  metatags: PropTypes.arrayOf(PropTypes.object),
  ogImage: PropTypes.string,
};

export default Seo;
