import styled from 'styled-components';
import { cssMobileFirstBreakpoints } from '../../constants/mediaBreakpoints';

const Container = styled.div`
  .section {
    color: var(--theming-color-text-dark);

    &__title {
      font-size: 28px;
      font-weight: 600;
      margin-bottom: 27px;
    }

    &__content {
      font-size: 14px;
    }

    &__form {
      font-size: 13px;
      padding: 42px 52px 30px 52px;
      border: 1px solid var(--theming-black);
      border-radius: 16px;
      max-width: 383px;
      margin: 0 auto 20px auto;
    }

    &__form-row {
      padding-bottom: 16px;
    }

    &__form-label {
      font-weight: 500;
      padding-bottom: 8px;
    }

    &__form-input {
      font-size: 15px;
      height: 48px;

      input:not([type=radio]) {
        border-radius: 16px;
        border: 1px solid #c4c4c4;
        color: #414042;
        outline: none;
        width: 100%;
        padding: 5px 14px;
        height: inherit;
      }
      
      &--input-and-select-group {
        display: flex;

        > * {
          border-radius: 16px;
          border: 1px solid #c4c4c4;
          color: #414042;
          outline: none;
        }

        > :first-child {
          padding: 0 14px;
          width: 100%;
          border-top-right-radius: 0 !important;
          border-bottom-right-radius: 0 !important;
        }

        > :last-child {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          margin-left: -2px;
          text-align: center;
        }
      }

      &--radio-group {
        display: flex;
        border: 1px solid #c4c4c4;
        border-radius: 16px;
      }
    }

    &__form-textarea {
      font-size: 15px;
      textarea {
        border-radius: 16px;
        border: 1px solid #c4c4c4;
        color: #414042;
        outline: none;
        width: 100%;
        padding: 5px 14px;
        height:100px
      }
    }

    &__form-select {
      width: 100%;
      height: 48px;

      > * {
        border-radius: 16px;
        border: 1px solid #c4c4c4;
        color: #414042;
        outline: none;
      }
      > select {
        height: 48px;
        width: 100%;
        background: #fff;
        font-size: 15px;
        padding: 0 14px;
      }
    }

    &__form-label-group {
      cursor: pointer;
      width: 50%;
      padding: 1px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__form-input-label {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #787878;

      > .grey-icon {
        display: inline;
      }

      > .blue-icon {
        display: none;
      }
    }

    &__form-button {
      padding: 26px 0;

      &--last {
        padding-bottom: 0;
      }
    }

    &__form-input-error {
      color: red;
      height: 4px;
    }

    &__form-radio-input {
      appearance: none;

      &:checked + .section__form-input-label {
        border: 1px solid #0356e8;
        border-radius: 16px;
        color: #0356e8;
        font-weight: 600;
        height: 100%;
        width: 100%;

        > .blue-icon {
          display: inline;
        }

        > .grey-icon {
          display: none;
        }
      }
      
      &.flex {
        display: flex;
        align-items: center;
        align-content: center;
        gap: 10px;
      }
    }
  }

  @media only screen and (${cssMobileFirstBreakpoints.tabletAndMore}) {
    display: flex;
    justify-content: flex-end;
    gap: 10%;

    .section-left:has(.gatsby-image-wrapper:not(.gatsby-image-wrapper-constrained)) {
      width:70%;
    }
    
    .section-right:has(.gatsby-image-wrapper:not(.gatsby-image-wrapper-constrained)) {
      width:70%;
    }
  }
`;

export default Container;
