import styled from 'styled-components';

const StyledSimpleInput = styled.input<{ customWidth?: string; customHeight?: string }>`
  && {
    border: 1px solid #c4c4c4;
    border-radius: 10px;
    height: ${({ customHeight }) => `${customHeight || 'fill-available'} `};
    width: ${({ customWidth }) => `${customWidth || 'fill-available'} `};

    font-size: 16px;
    padding-left: 16px;
    color: #414042;
    font-family: 'Poppins';
    font-weight: 400;
    outline: none;
  }
`;
export default StyledSimpleInput;
