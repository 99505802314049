import React from 'react';
import { GenericParagraphProps } from '../../drupalPagesGenerator/GenericParagraphs/genericParagraph.componentProps';
import StyledHeaderImageCarousel from './styledHeaderImageCarousel';
import { Carousel } from 'react-bootstrap';
import { navigate } from 'gatsby';
import DonateButton from '../../components/DonateButton/DonateButton';
import Img from 'gatsby-image';
import WithDesktopMobileSwitch from '../../HOCs/WithDesktopMobileSwitch/WithDesktopMobileSwitch';

const HeaderImageCarousel = ({ button, desktopImages, linkImages, mobileImages, description, title }: GenericParagraphProps) => {
  return (
    <StyledHeaderImageCarousel>
      <WithDesktopMobileSwitch
        desktopContent={
          <Carousel controls={desktopImages?.length > 1} indicators={false}>
            {(desktopImages || []).map((data, index) => (
              <Carousel.Item key={`${index}desktopCarousel`}>
                {linkImages[index] &&
                  <a href={linkImages[index].url.path}>
                      <Img fluid={data.image?.childImageSharp?.fluid} alt={data.metadata.alt} loading='eager' className='carouselImage' />
                  </a>
                }
                {!linkImages[index] &&
                  <Img fluid={data.image?.childImageSharp?.fluid} alt={data.metadata.alt} loading='eager' className='carouselImage' />
                }
              </Carousel.Item>
            ))}
          </Carousel>
        }
      /* mobileContent={
        <Carousel controls={mobileImages?.length > 1} indicators={false}>
          {(mobileImages || []).map((data, index) => (
            <Carousel.Item key={`${index}mobileCarousel`}>
              <Img fluid={data.image?.childImageSharp?.fluid} alt={data.metadata.alt} loading='eager' />
            </Carousel.Item>
          ))}
        </Carousel>
      } */
      />

      <div className='panel'>
        {title && <div className='panel__title' dangerouslySetInnerHTML={{ __html: title }} />}
        {description && <div className='panel__description' dangerouslySetInnerHTML={{ __html: description }} />}
        {button?.buttonText && <DonateButton onClick={() => navigate(button.ref)}>{button.buttonText}</DonateButton>}
      </div>
    </StyledHeaderImageCarousel>
  );
};

export default HeaderImageCarousel;
