import React from 'react';
import StyledFourCardsGroup from './styledFourCardsGroup';
import WithCenteredContent from '../../HOCs/WithCenteredContent/WithCenteredContent';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

const FourCardsGroup = ({ cardData, sectionTitle }: any) => {
  return (
    <StyledFourCardsGroup>
      <WithCenteredContent>
        <>
          {sectionTitle && <div className='section__title' dangerouslySetInnerHTML={{ __html: sectionTitle }} />}

          <div className='section__cards'>
            {(cardData?.results || []).map((data: any, index: number) => (
              <div
                className='card'
                style={{
                    background: data.field_color,
                    cursor: data.field_link ? "pointer" : "default"
                }}
                onClick={() =>
                   data.field_link ? window.open(data.field_link) : false
                }
                key={`${index}cardsGroup`}
              >
                <div className='card__left-side'>
                  <Img fixed={cardData.cardImages[index]?.childImageSharp?.fixed} alt={data?.title || ''} className='card__icon' />
                </div>
                <div className='card__right-side'>
                  {data.title && <div className='card__title' dangerouslySetInnerHTML={{ __html: data.title }} />}
                  {data.body && <div className='card__description' dangerouslySetInnerHTML={{ __html: data.body }} />}
                </div>
              </div>
            ))}
          </div>
        </>
      </WithCenteredContent>
    </StyledFourCardsGroup>
  );
};

export default FourCardsGroup;

export const fragment = graphql`
  fragment ParagraphPrinciplesGraphql1ViewResult on DRUPAL_PrinciplesAssistGraphql1ViewResult {
    type: __typename
    results {
      field_icon
      body
      field_color
      title
    }
    cardImages {
      childImageSharp {
        fixed(width: 80, height: 80) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
  fragment ParagraphReportsGraphql1ViewResult on DRUPAL_ReportsAssistGraphql1ViewResult {
    type: __typename
    results {
      field_icon
      body
      field_color
      title
      field_link
    }
    cardImages {
      childImageSharp {
        fixed(width: 80, height: 80) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;
