import { graphql } from 'gatsby';
import React from 'react';
import TextContent from '../../drupalSections/TextContent/textContent';

const LongTextParagraph = ({ node }: any) => {
  const { fieldText, fieldHasWhiteBackground } = node;
  return <TextContent content={fieldText.processed} hasWhiteBackground={!!fieldHasWhiteBackground} />;
};

export default LongTextParagraph;

export const fragment = graphql`
  fragment ParagraphLongText on DRUPAL_ParagraphLongTextParagraph {
    id
    type: entityBundle
    fieldSpacingAfter
    fieldHasWhiteBackground
    fieldText {
      processed
    }
  }
`;
