import styled from 'styled-components';
import { cssMobileFirstBreakpoints } from '../../constants/mediaBreakpoints';

const StyledCenteredContentImage = styled.div`
  && {
    position: relative;
    min-height: 449px;
    background: #f9f9f9;
  }

  .section {
    position: absolute;
    z-index: 1;
    bottom: 24px;
    max-width: 330px;
    text-align: center;

    left: 0;
    right: 0;
    margin: 0px auto;

    &__title {
      font-weight: 600;
      font-size: 24px;
      color: var(--theming-footer-gray);
      margin-bottom: 16px;
    }

    &__description {
      font-size: 14px;
      color: var(--theming-footer-gray);

      margin-bottom: 16px;
    }
  }

  @media only screen and (${cssMobileFirstBreakpoints.mobileAndMore}) {
    .section {
      bottom: 10%;
      max-width: 580px;

      &__title {
        font-size: 32px;
      }

      &__description {
        font-size: 16px;
        color: var(--theming-footer-gray);

        margin-bottom: 40px;
      }
    }
  }
`;

export default StyledCenteredContentImage;
