import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { StaticImage } from 'gatsby-plugin-image';
import CustomButton from '../../components/button';
import { ButtonBackgroundTypes } from '../../components/button/button';
import axios from 'axios';
import { useQueryParam, StringParam } from 'use-query-params';

const DonateForm = ({ fields }: any) => {
  const isPaymentSuccess = useQueryParam('success', StringParam);
  const isPaymentCanceled = useQueryParam('canceled', StringParam);

  const redirectToPayment = async (data: any) => {
    if (data.op === 'card') {
      try {
        const {
          data: { url },
        } = await axios.post('/api/checkout', {
          amount: data.amount,
          currency: data.currency,
          causes: data.causes,
        });

        //setMessage("Redirect to Stripe Checkout...");
        window.location = url;
      } catch (error) {}
    }

    if (data.op === 'paypal') {
      if (['RON', 'EUR'].includes(data.currency)) {
        window.open('https://www.paypal.com/donate/?hosted_button_id=ZJ66GQX8JBTBN', '_blank');
      } else {
        window.open('https://www.paypal.com/donate/?hosted_button_id=WUNNNS8Y9855L', '_blank');
      }
    }
  };

  const options = ['RON', 'USD', 'EUR'];
  const causesOptions = fields.fieldSelectValues;

  const {
    register,
    watch,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      amount: '',
      currency: options[0],
      causes: causesOptions[0],
      frequency: 'once',
      op: 'card',
    },
  });

  const watchFieldCurrency = watch('currency');

  const amountValidate = () => {
    return parseInt(watch('amount')) >= 5;
  };

  const onSubmit = (data: any) => {
    redirectToPayment(data);
  };

  return (
    <div className='section__form'>
      <form onSubmit={handleSubmit(onSubmit)}>
        {isPaymentSuccess[0] === 'true' && <p className='alert alert-success'>Thank you for your donation!</p>}
        {isPaymentCanceled[0] === 'true' && <p className='alert alert-danger'>Something went wrong, try again!</p>}
        <div className='section__form-row'>
          <label className='section__form-label'>{fields.fieldLabels[0]}</label>
          <div className='section__form-input section__form-input--input-and-select-group'>
            <input
              type='number'
              {...register('amount', {
                validate: {
                  minAmountValidate: amountValidate,
                },
              })}
            />
            <select
              {...register('currency', {
                validate: {
                  minAmountValidate: amountValidate,
                },
              })}
            >
              {options.map((option, index) => (
                <option value={option} key={`${index}currency`}>
                  {option}
                </option>
              ))}
            </select>
          </div>
          {errors.amount?.type === 'minAmountValidate' && (
            <p className='section__form-input-error'>Minim amount is 5 {watchFieldCurrency}</p>
          )}
        </div>

        {/* not used yet */}
        {/* <div className='section__form-row'>
            <label className='section__form-label'>Frecvența Donațiilor</label>
            <div className='section__form-input section__form-input--radio-group'>
              <label className='section__form-label-group'>
                <input type='radio'  className='section__form-radio-input' value='monthly' {...register('frequency', { required: true })} />
                <div className='section__form-input-label'>Lunar</div>
              </label>
              <label className='section__form-label-group'>
                <input type='radio'  className='section__form-radio-input' value='once' {...register('frequency', { required: true })} />
                <div className='section__form-input-label'>O dată</div>
              </label>
            </div>
          </div> */}

        <div className='section__form-row'>
          <label className='section__form-label'>{fields.fieldLabels[2]}</label>
          <div className='section__form-select'>
            <select {...register('causes')}>
              {causesOptions.map((cause: any, index: any) => (
                <option value={cause} key={`${index}causes`}>
                  {cause}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className='section__form-row'>
          <label className='section__form-label'>{fields.fieldLabels[1]}</label>
          <div className='section__form-input section__form-input--radio-group'>
            <label className='section__form-label-group'>
              <input type='radio' className='section__form-radio-input' value='card' {...register('op', { required: true })} />
              <div className='section__form-input-label'>
                <StaticImage
                  style={{
                    marginRight: '10px',
                    marginTop: '2px',
                  }}
                  src='../../images/svg/blue-card.svg'
                  alt='Heart'
                  class='blue-icon'
                />
                <StaticImage
                  style={{
                    marginRight: '10px',
                    marginTop: '2px',
                  }}
                  src='../../images/svg/card.svg'
                  alt='Heart'
                  class='grey-icon'
                />
                Card
              </div>
            </label>
            <label className='section__form-label-group'>
              <input className='section__form-radio-input' type='radio' value='paypal' {...register('op', { required: true })} />
              <div className='section__form-input-label'>
                <StaticImage
                  style={{
                    marginRight: '10px',
                    marginTop: '2px',
                  }}
                  src='../../images/svg/blue-paypal.svg'
                  alt='Paypal'
                  class='blue-icon'
                />
                <StaticImage
                  style={{
                    marginRight: '10px',
                    marginTop: '2px',
                  }}
                  src='../../images/svg/paypal.svg'
                  alt='Paypal'
                  class='grey-icon'
                />
                Paypal
              </div>
            </label>
          </div>
        </div>

        <div className='section__form-button'>
          <CustomButton
            color='var(--theming-white)'
            backgroundColor={errors.amount ? ButtonBackgroundTypes.PRIMARYDISABLED : ButtonBackgroundTypes.PRIMARY}
            border={errors.amount ? 'var(--theming-text-gray)' : 'var(--theming-main-blue)'}
            width='280px'
          >
            <StaticImage
              style={{
                marginRight: '10px',
                marginTop: '4px',
              }}
              src='../../images/svg/heart.svg'
              alt='Heart'
            />
            {fields.fieldSubmit}
          </CustomButton>
        </div>
      </form>
      {/* not used yet */}
      {/* <div className='section__form-button--last'>
        <CustomButton
          color='#8D8D8D'
          backgroundColor={ButtonBackgroundTypes.TRANSPARENT}
          border='transparent'
          width='280px'
          height='20px'
        >
          Vreau să mă dezabonez
        </CustomButton>
      </div> */}
    </div>
  );
};

export default DonateForm;
