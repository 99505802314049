import { graphql } from 'gatsby';
import React from 'react';
import ActivitySummary from '../../drupalSections/ActivitySummary/activitySummary';
import FourCardsGroup from '../../drupalSections/FourCardsGroup/fourCardsGroup';
import AvatarsGroup from '../../drupalSections/AvatarsGroup/avatarsGroup';
import SponsorsAndPartners from '../../drupalSections/SponsorsAndPartners/sponsorsAndPartners';
import Badges from "../../drupalSections/Bagdes/badges";
import Faq from "../../drupalSections/FAQ/faq";

const sections: any = {
  DRUPAL_PrinciplesAssistGraphql1ViewResult: FourCardsGroup,
  DRUPAL_ReportsAssistGraphql1ViewResult: FourCardsGroup,
  DRUPAL_ActivitiesAssistGraphql1ViewResult: ActivitySummary,
  DRUPAL_ActivitiesAssistGraphql2ViewResult: ActivitySummary,
  DRUPAL_MembersAssistGraphql1ViewResult: AvatarsGroup,
  DRUPAL_VolunteersAssistGraphql1ViewResult: AvatarsGroup,
  DRUPAL_SponsorsAssistGraphql1ViewResult: SponsorsAndPartners,
  DRUPAL_PartnersAssistGraphql1ViewResult: SponsorsAndPartners,
  DRUPAL_PartnersAssistGraphql2ViewResult: SponsorsAndPartners,
  DRUPAL_BadgesAssistGraphql1ViewResult: Badges,
  DRUPAL_FaqAssistGraphql1ViewResult: Faq,
};

const ViewParagraph = ({ node }: any) => {
  const currentLang = node.entityLanguage.id;
  const { fieldViewReference, fieldText, fieldLinkButton } = node;

  const { type } = fieldViewReference[currentLang];

  const CurrentSection = sections[type];

  return (
    <CurrentSection
      sectionTitle={fieldText?.processed}
      cardData={fieldViewReference[currentLang]}
      buttonLinkText={fieldLinkButton?.title}
      buttonLinkUri={fieldLinkButton?.url?.path}
    />
  );
};

export default ViewParagraph;

export const fragment = graphql`
  fragment ParagraphView on DRUPAL_ParagraphViewsParagraph {
    id
    type: entityBundle
    fieldSpacingAfter
    fieldText {
      processed
    }
    fieldLinkButton {
      url {
        path
      }
      title
    }
    fieldViewReference {
      en: viewDerivative(filter: { langcode: "EN" }, page: $CurrentPage) {
        ...ParagraphFaqGraphql1ViewResult
        ...ParagraphPrinciplesGraphql1ViewResult
        ...ParagraphReportsGraphql1ViewResult
        ...ParagraphActivitiesGraphql1ViewResult
        ...ParagraphActivitiesGraphql2ViewResult
        ...ParagraphMembersGraphql1ViewResult
        ...ParagraphVolunteersGraphql1ViewResult
        ...ParagraphSponsorsGraphql1ViewResult
        ...ParagraphPartnersGraphql1ViewResult
        ...ParagraphPartnersGraphql2ViewResult
        ...ParagraphBadgesGraphql1ViewResult
      }
      ro: viewDerivative(filter: { langcode: "RO" },  page: $CurrentPage) {
        ...ParagraphFaqGraphql1ViewResult
        ...ParagraphPrinciplesGraphql1ViewResult
        ...ParagraphReportsGraphql1ViewResult
        ...ParagraphActivitiesGraphql1ViewResult
        ...ParagraphActivitiesGraphql2ViewResult
        ...ParagraphMembersGraphql1ViewResult
        ...ParagraphVolunteersGraphql1ViewResult
        ...ParagraphSponsorsGraphql1ViewResult
        ...ParagraphPartnersGraphql1ViewResult
        ...ParagraphPartnersGraphql2ViewResult
        ...ParagraphBadgesGraphql1ViewResult
      }
    }
  }
`;
