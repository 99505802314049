import React from 'react';

import { GenericParagraph } from './GenericParagraphs/genericParagraph';
import ViewParagraph from './ViewParagraph/ViewParagraph';
import LongTextParagraph from './LongTextParagraph/LongTextParagraph';
import TestimonialParagraph from './TestimonialParagraph/TestimonialParagraph';
import SingleImageParagraph from './SingleImageParagraph/SingleImageParagraph';
import MultipleImagesParagraph from './MultipleImagesParagraph/MultipleImagesParagraph';
import FormParagraph from './FormParagraph/FormParagraph';
import ContainerParagraph from './ContainerParagraph/ContainerParagraph';

const globalSectionTypes: any = {
  generic_paragraph: GenericParagraph,
  views_paragraph: ViewParagraph,
  long_text_paragraph: LongTextParagraph,
  testimonial_paragraph: TestimonialParagraph,
  single_image_paragraph: SingleImageParagraph,
  multiple_images_paragraph: MultipleImagesParagraph,
  form_paragraph: FormParagraph,
  container_paragraph: ContainerParagraph,
};

export const getSection = (node: any) => {
  const { entity } = node;
  const { type, id, fieldSpacingAfter = '' } = entity || {};

  if (globalSectionTypes?.[type]) {
    const Section = globalSectionTypes[type];

    const marginBottomSpacingClassName: any = {
      small: 'margin-bottom-small',
      normal: 'margin-bottom-medium',
      large: 'margin-bottom-large',
    };

    return (
      <div key={`${id}sectionDiv`} className={marginBottomSpacingClassName?.[fieldSpacingAfter] || ''}>
        <Section key={`${id}section`} node={entity} />
      </div>
    );
  }

  return <p key={id}>Unknown type {node.__typename}</p>;
};
