import React from 'react';
import StyledButton from './StyledButton';

export enum ButtonBackgroundTypes {
  PRIMARY = 'PRIMARY',
  PRIMARYDISABLED = 'PRIMARYDISABLED',
  TRANSPARENT = 'TRANSPARENT',
  WHITE = 'WHITE',
  YELLOW = 'YELLOW',
}

interface ButtonProps {
  children: React.ReactNode;
  width?: string;
  height?: string;
  backgroundColor?: ButtonBackgroundTypes;
  border?: string;
  color?: string;
  fontSize?: string;
  onClick?: (e: any) => void;
  padding?: string;
  marginLeft?: string;
}

const backgroundTypeToColor = {
  [ButtonBackgroundTypes.PRIMARY]: 'var(--theming-main-blue)',
  [ButtonBackgroundTypes.PRIMARYDISABLED]: 'var(--theming-text-gray)',
  [ButtonBackgroundTypes.TRANSPARENT]: 'transparent',
  [ButtonBackgroundTypes.WHITE]: 'var(--theming-white)',
  [ButtonBackgroundTypes.YELLOW]: 'var(--theming-main-yellow)',
};

const CustomButton = ({
  children,
  width = '192px',
  height = '48px',
  fontSize = '16px',
  backgroundColor = ButtonBackgroundTypes.PRIMARY,
  border = 'transparent',
  color = "'var(--theming-main-blue)'",
  onClick,padding,marginLeft,
}: ButtonProps) => {
  return (
    <StyledButton
      onClick={onClick}
      width={width}
      height={height}
      fontSize={fontSize}
      background={backgroundTypeToColor[backgroundColor]}
      borderColor={border}
      contentColor={color}
      padding={padding}
      marginLeft={marginLeft}
    >
      {children}
    </StyledButton>
  );
};

export default CustomButton;
