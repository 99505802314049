import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import CustomButton from '../../components/button';

const DonateButton = ({ children, onClick }: any) => {
  return (
    <CustomButton color='white' onClick={onClick}>
      <>
        <StaticImage
          style={{
            marginRight: '10px',
            marginTop: '4px',
          }}
          src='../../images/svg/heart.svg'
          alt='Heart'
        />
        {children}
      </>
    </CustomButton>
  );
};

export default DonateButton;
